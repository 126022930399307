import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import * as Constants from '../../../store/constants/all';
import * as Actions from '../../../store/actions/general';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import * as Types from '../../../store/types';
import { SolutionCourseAddFormVal } from './validations/solution-course-form-val';
import {
  CourseFormInitialValues,
  ExamPeriodCourseTermLectureLocations
} from '../../../store/constants/course-const';
import Translator from '../../../services/translate-factory';
import MultiSelectionTableClassroom from './multi-selection-table-classroom';
import * as GT from '../../../tools/general-tools';
import SolutionCourseAddDetailTable from './add-detail-table';

const T = Translator.create();
const equal = require('deep-equal');
const Logger = Log.create('SolutionCourseAddModal');

let combined_values_selected: Array<any> = new Array<any>();

class SolutionCourseAddModal extends Component<any, any> {
  state: any = {
    isAdded: false,
    alreadyInTheList: false,
    term_id: -1,
    locale: '',
    isCourseSelected: false,
    model: Object.assign({}, CourseFormInitialValues)
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.spliceSubModalValues();
  }

  getCourseAtSolutionAddModal = (courseId: number) => {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_BY_ID, courseId, 'course-detail-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  spliceSubModalValues() {
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  disableSaveButton = (values: Types.ISolutionCourseAddModal) => {
    let disableValue: boolean = false;
    if (this.state.courseDetail && this.state.courseDetail.campus_id === 1003) {
      this.spliceSubModalValues();
      if (values.start_date === null || values.start_date === undefined || values.start_hour === undefined || values.courses === undefined || values.courses === null) {
        disableValue = true;
      }
    } else {
      if (values.start_date === null || values.start_date === undefined || values.start_hour === undefined || values.courses === undefined || values.courses === null) {
        disableValue = true;
      }
    }
    return disableValue;
  };

  onFormSave = (values: Types.ISolutionCourseAddModal, FormActions: FormikActions<Types.ISolutionCourseAddModal>) => {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.props.dispatch(Actions.Notification(result.course.name + ' (' + result.course.course_code + ') - ' + result.course.course_id + ' ' + T.t('notification_lesson_added'), 'gen_success'));
        this.setClose(true);
      }
    };

    if (combined_values_selected == undefined || combined_values_selected.length == 0) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t("gen_cannot_leave_empty"),
          body: T.t("gen_list_isnot_left_blank"),
          name: 'combining_failed',
          icon: 'error_outline',
          iconColor: 'red'
        }));
    } else {
      let model = {
        term_id: this.props.term_id,
        solution_id: this.props.solutionId,
        start_date: values.start_date + ' ' + values.start_hour,
        course_id: Number(values.course_id),
        classroom_ids: combined_values_selected.map((item: any) => item.classroom_id),
        invigilator_ids: combined_values_selected.map((item: any) => item.invigilator_id),
        student_counts: combined_values_selected.map((item: any) => item.student_count),
      }

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_ADD_COURSE, model, 'course-form-spin', resultCallback)
      );
    }

    this.props.resultSearchObjectFunction(
      [Number(values.course_id)],
    );
  }

  multiSelecteds(
    combined_values: any
  ) {
    combined_values_selected = combined_values
  }

  static getDerivedStateFromProps(props: any, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (props && props.examDates) {
      hasNewState = true;
      const startDate = moment(props.examDates.start_date).toDate();
      const endDate = moment(props.examDates.end_date).toDate();
      const endHour = moment(props.examDates.end_hour, 'HH:mm').format('H');
      const duration_hour = moment(props.examDates.slot_duration, 'HH:mm').format('H');
      const max = parseInt(endHour, 10);

      state.minDate = moment(startDate).format('YYYY-DD-MM');
      state.minHour = props.examDates.start_hour;
      state.maxDate = moment(endDate).format('YYYY-DD-MM');
      const maxHour = moment(max - Number(duration_hour), 'H').format('HH:mm');
      state.maxHour = maxHour;
    }

    if (props && props.courseDetail) {
      hasNewState = true;
      state.courseDetail = props.courseDetail;
    } else {
      state.courseDetail = undefined;
    }

    state.locale = GT.getLocaleFromLangCode();

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const formInitialValues = {};

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_solution-exam-add')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="alert alert-warning" role="alert" style={{ maxWidth: 'none', textAlign: 'center' }}>
                {T.t('gen_solution_add_warning')}
              </div>
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validationSchema={SolutionCourseAddFormVal}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ISolutionCourseAddModal>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      this.disableSaveButton(values);
                      if (values.course_id) {
                        this.state.isCourseSelected = true;
                      }
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 d-flex align-items-center justify-content-center b-bottom-gray">
                              <div className="col-md-4 mt-3">
                                <div className="react-select-container">
                                  <div className="form-input form-group with-icon date-picker">
                                    <Flatpickr
                                      placeholder={T.t('gen_start_date')}
                                      value={this.state.start_date}
                                      options={{
                                        dateFormat: 'Y-d-m',
                                        clickOpens: true,
                                        allowInput: true,
                                        locale: this.state.locale,
                                        maxDate: this.state.maxDate ? this.state.maxDate : undefined,
                                        minDate: this.state.minDate ? this.state.minDate : undefined
                                      }}
                                      onClose={(value) => {
                                        values.start_date = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                                        this.setState(this.state);
                                      }}
                                    />
                                    <label style={{ top: "-13px" }}>{T.t('gen_start_date')}</label> <i className="material-icons">insert_invitation</i>
                                  </div>
                                </div>
                                {errors && errors.start_date && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                              <div className="col-md-3 form-input form-group with-icon">
                                <div className="add-custom-tag mt-3">
                                  <Flatpickr
                                    id='start_time'
                                    value={props.values.start_hour}
                                    name="start_hour"
                                    placeholder={T.t('gen_select_time')}
                                    options={{
                                      enableTime: true,
                                      dateFormat: 'H:i',
                                      noCalendar: true,
                                      time_24hr: true,
                                      maxDate: this.state.maxHour ? this.state.maxHour : undefined,
                                      minDate: this.state.minHour ? this.state.minHour : undefined
                                    }}
                                    onClose={(value) => props.setFieldValue('start_hour', moment(value[0]).format('HH:mm'))}
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="start-time-datepicker">{T.t('gen_start_time')}</label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course')}</label>
                                    <Select
                                      id='select_course'
                                      className="react-select"
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      options={
                                        this.props.solutionCourseAddSelectOptions && this.props.solutionCourseAddSelectOptions.courses
                                          ? this.props.solutionCourseAddSelectOptions.courses
                                          : []
                                      }
                                      placeholder={T.t('gen_select_course')}
                                      value={props.values.courses ? props.values.courses : null}
                                      onChange={(option: any) => {
                                        props.setFieldValue('courses', option);
                                        props.setFieldValue('course_id', option && option.value);
                                        this.getCourseAtSolutionAddModal(option && option.value)
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_course')}
                                    />
                                  </div>
                                  {errors && errors.course_id && props.submitCount > 0 && (
                                    <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <MultiSelectionTableClassroom
                            combined_values={combined_values_selected}
                            selected_course={props.values.courses ? props.values.courses : null}
                            multiSelectionObjectFunction={this.multiSelecteds}
                          />
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                id='button_save'
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                disabled={this.disableSaveButton(values)}
                                onClick={() => {
                                  props.handleSubmit()
                                }}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                {this.state.courseDetail && this.state.isCourseSelected && (
                  <SolutionCourseAddDetailTable
                    detail={this.state.courseDetail ? this.state.courseDetail : null}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    solutionCourseAddSelectOptions: store.state.select_options && store.state.select_options.solutionCoursePage,
    courseDetail: store.state.course_page && store.state.course_page.form,
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionCourseAddModal);

export default container;
