import React from 'react';
import { Log } from 'ng2-logger';
import ImageViewer from './image-viewer';
import Translator from '../../services/translate-factory';

const L = Log.create('ImageHolder');
const T = Translator.create();

export default class ImageHolder extends React.Component<any, any> {

  _mounted: boolean = false;
  _imgViewer: ImageViewer | undefined;

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  updateImage = () => {
    if (this._mounted) {
      if (this._imgViewer) {
        this._imgViewer._index = 0;
        this._imgViewer.updateImage();
      }
      this.forceUpdate();
    }
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this);
    }
  }

  onLoad = (imageViewer: ImageViewer) => {
    this._imgViewer = imageViewer;
  }

  render() {
    const src: string = this.props.src;

    return (
      <React.Fragment>
        <div className="image-holder">
          <ImageViewer srcs={this.props.srcs && this.props.srcs.length > 0 ? this.props.srcs : ['data:image/png;base64,' + src, this.props.default]} onLoad={this.onLoad} onClick={this.onClick} />
          <small className="row ml-md-4">{this.props.title}</small>
          <button type="button" className="btn-back btn mt-1" onClick={this.onClick}>
            <i className="material-icons">cloud_upload</i>&nbsp;{T.t('gen_upload')}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

