import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/constants/all';
import * as Actions from '../../store/actions/general';
import * as Types from '../../store/types';
import * as Constants from '../../store/constants/all';
import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { LoginFormValidation } from './validations/login-form-val';
import ForgotFormValidation from './validations/forgot-form-val';
import Translator from '../../services/translate-factory';
import { Log } from 'ng2-logger';

const T = Translator.create();
const L = Log.create('LoginForm');

class LoginForm extends React.Component<Types.ILoginPageProps, Types.ILoginPageState> {
  state: Types.ILoginPageState = {
    activeArea: 'login',
    loginForm: {
      username_loginpage: '',
      password_loginpage: '',
      account_id: ''
    },
    forgotForm: {
      email: '',
      account_id: '',
      token: '',
      password: '',
      password_confirm: '',
      usernameOrEmail: ''
    },
    term_id: -1,
    term_type: -1,
    solution_id: -1,
    termDefinationModalIsOpen: false,
    institutionName: ''
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  onLogin = (model: Types.ILoginView, LoginActions: FormikActions<Types.ILoginView>) => {
    if (this.props.account) {
      model.account_id = '1'; //this.props.account.account_id;
    }
    let modelAPI: Types.ILoginViewAPI = {
      account_id: model.account_id,
      username: model.username_loginpage + "||" + window.location.hostname,
      password: model.password_loginpage
    }
    this.props.dispatch(Actions.ApiRequest(actions.login.LOGIN_AUTH_TOKEN, modelAPI, 'login-form-spin'));
    LoginActions.setSubmitting(false);
  };

  onForgot = (model: Types.IForgotView, LoginActions: FormikActions<Types.IForgotView>) => {
    if (this.props.account) {
      model.account_id = this.props.account.account_id;
    }
    let newModel = {
      usernameOrEmail: model.email
    }
    this.props.dispatch(Actions.ApiRequest(actions.login.LOGIN_FORGOT_PASSWORD, newModel, 'login-form-spin'));
    LoginActions.setSubmitting(false);
  };

  onAreaChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e && e.currentTarget && e.currentTarget.dataset.area) {
      this.setState({ activeArea: e.currentTarget.dataset.area });
    }
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  render() {
    if (this.state.activeArea === 'login') {
      return (
        <React.Fragment>
          <h1 className="wellcome-tag">{T.t("gen_welcome")}</h1>
          <Formik
            initialValues={this.state.loginForm}
            onSubmit={this.onLogin}
            validationSchema={LoginFormValidation(T)}
          >
            {(props: FormikProps<Types.ILoginView>) => {
              const { values, handleChange, errors, handleBlur, handleSubmit } = props;
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className="white-container mt-6">
                    <div className="form-group">
                      <div className="form-input">
                        <input
                          id="username_loginpage"
                          type="text"
                          value={props.values.username_loginpage}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>{T.t('gen_email')} / {T.t('gen_username')}</label>
                      </div>
                      {errors && errors.username_loginpage && props.submitCount > 0 && (
                        <div className="error">{errors && errors.username_loginpage}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="form-input">
                        <input
                          id="password_loginpage"
                          type="password"
                          value={props.values.password_loginpage}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>{T.t('gen_password')}</label>
                      </div>
                      {errors && errors.password_loginpage && props.submitCount > 0 && (
                        <div className="error">{errors && errors.password_loginpage}</div>
                      )}
                      <ErrorMessage component="div" className="error" name="password" />
                    </div>

                    <div className="row align-items-center">
                      <div className="col">
                        <a className="forgot" href="javascript:;" data-area="forgot" onClick={this.onAreaChange}>
                          {T.t('gen_password_forgot')}
                        </a>
                      </div>
                      <div className="col text-right">
                        <button className="btn btn-primary" type="submit" disabled={props.isSubmitting}>
                          {T.t('gen_login')} <i className="material-icons">arrow_forward</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </React.Fragment>
      );
    }
    if (this.state.activeArea === 'forgot') {
      return (
        <React.Fragment>
          <h1>{T.t('gen_title_not_alone')}</h1>
          <div className="white-container mt-6">
            <p>
              {T.t('gen_password_forgot_body')}
            </p>
            <Formik initialValues={this.state.forgotForm} onSubmit={this.onForgot} validationSchema={ForgotFormValidation(T)}>
              {(props: FormikProps<Types.IForgotView>) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="form-group">
                      <div className="form-input">
                        <input name="email" type="text" value={props.values.email} onChange={props.handleChange} onBlur={props.handleBlur} />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>{T.t('gen_email')}</label>
                      </div>
                      <ErrorMessage component="div" className="error" name="email" />
                    </div>

                    <div className="row align-items-center">
                      <div className="col">
                        <a href="javascript:;" data-area="login" onClick={this.onAreaChange}>
                          <i className="material-icons mr-3">keyboard_backspace</i> {T.t("gen_previous")}
                        </a>
                      </div>
                      <div className="col text-right">
                        <button className="btn btn-primary" type="submit" disabled={props.isSubmitting}>
                          {T.t('gen_send')} <i className="material-icons">arrow_forward</i>
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ILoginPageProps): Types.ILoginPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    account: store.state.account,
    definitions: store.state.definitions
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(LoginForm);

export default container;
