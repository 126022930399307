import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../../store/types';
import Translator from '../../../services/translate-factory';
import * as Actions from '../../../store/actions/general';
import Select from 'react-select';
import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import * as Constants from '../../../store/constants/all';

const T = Translator.create();

let sectionFormatValidation: boolean = false;

function getInitialState(): any {
  const initialValues: any = {
    isAdded: false,
    loadedProps: false,
    faculties_selected: {},
    faculty_id_selected: 0,
    programs_selected: {},
    program_id_selected: 0,
    section: "",
    combined_values: [],
    combined_values_temp: [],
    combined_value_query: ""
  };
  return Object.assign({}, initialValues);
}

class MultiSelectionTable extends Component<any, any> {
  state: {
    isAdded: boolean;
    alreadyInTheList: boolean;
    loadedProps: boolean;
    faculties_selected: Types.ISelectOption;
    faculty_id_selected: number;
    programs_selected: Types.ISelectOption;
    program_id_selected: number;
    section: string;
    all_ids?: Array<number>;
    selected_ids?: Array<number>;
    combined_values: Array<any>;
    combined_values_temp: Array<any>;
    combined_value_query: string;
  } = getInitialState();

  getProgramsByFacultiesAtActivities = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    let addModal = {
      faculty_id: values.faculty_id_selected,
      faculty: values.faculties_selected,
      program_id: values.program_id_selected,
      program: values.programs_selected,
      section: values.section == "" ? '1' : values.section,
      index: this.state.combined_values.length + 1,
    }

    this.state.combined_values.push(addModal)
    this.setState(this.state);

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  onDeleteRow = (selected_ids: any) => {
    if (this.state.selected_ids) {
      let isTempCombinedValues: boolean = this.state.combined_value_query != undefined && this.state.combined_value_query != '' && this.state.combined_values_temp && this.state.combined_values_temp.length != 0;
      if (isTempCombinedValues) {
        selected_ids && selected_ids.forEach((id: any, index: number) => {
          this.state.combined_values_temp = this.state.combined_values_temp.filter((item: any) => item.index != id)
        });
      } else {
        selected_ids && selected_ids.forEach((id: any, index: number) => {
          this.state.combined_values = this.state.combined_values.filter((item: any) => item.index != id)
        });
      }
      this.setState({
        ...this.state,
        combined_values: isTempCombinedValues ? this.state.combined_values_temp : this.state.combined_values,
        all_ids: [],
        selected_ids: [],
      });
    }
  };

  isIncludeCurrentForm = (values: any) => {
    let table_values = values.combined_values && values.combined_values.map((element: any, index: number) => (
      {
        values:
          element.faculty_id + ',' +
          element.program_id + ',' +
          element.section
      }));

    if (table_values !== undefined) {
      let current_form_values = { values: values.faculty_id_selected + ',' + values.program_id_selected + ',' + values.section }
      if (table_values!.some((e: { values: string; }) => e.values == current_form_values.values)) {
        this.state.alreadyInTheList = true;
        this.state.isAdded = true;
      } else if (this.state.isAdded) {
        this.state.alreadyInTheList = false;
        this.state.isAdded = false;
      } else {
        this.state.alreadyInTheList = false;
        this.state.isAdded = false;
      }
    }

    const regexSection = /^[a-zA-Z0-9.]*$/;
    if (values.section) {
      if (regexSection.test(values.section) && values.section.length < 6) {
        sectionFormatValidation = false;
      } else {
        sectionFormatValidation = true;
      }
    }
  };

  onSelectCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.combined_values ? this.state.combined_values.map((item: any) => item.index) : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        let newModel = {

        };
        this.setState(this.state);

        this.setState({
          ...this.state,
          all_ids: this.state.combined_values ? this.state.combined_values.map((item: any) => item.index) : [],
          selected_ids: this.state.combined_values ? this.state.combined_values.map((item: any) => item.index) : []
        })
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: []
        });
      }
    }
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (props.combined_values && props.combined_values.length != 0 && props.combined_values != state.combined_values && state.combined_values.length == 0 && !state.loadedProps) {
      state.combined_values = props.combined_values;
      hasNewState = true;
      state.loadedProps = true;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  render() {
    this.props.multiSelectionObjectFunction(
      this.state.combined_values,
    );

    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.putToTable(this.state, true);
        }}
      >
        {(props: FormikProps<any>) => {
          const { values, handleChange, errors, handleBlur, handleSubmit } = props;

          this.isIncludeCurrentForm(this.state);

          if (this.state.combined_value_query != undefined && this.state.combined_value_query != '') {
            if (this.state.combined_values_temp.length == 0) {
              this.state.combined_values_temp = this.state.combined_values;
            }
            this.state.combined_values = this.state.combined_values.filter((element: any) =>
              element.faculty.label.toLowerCase().includes(this.state.combined_value_query.toLowerCase()) ||
              element.program.label.toLowerCase().includes(this.state.combined_value_query.toLowerCase()) ||
              element.section.toLowerCase().includes(this.state.combined_value_query.toLowerCase())
            );
          } else if (this.state.combined_values_temp && this.state.combined_values_temp.length != 0) {
            this.state.combined_values = this.state.combined_values_temp;
            this.state.combined_values_temp = [];
          }

          return (
            <div className="col-md-12">
              <hr />
              <div className="col-md-12">
                <div className="add-custom-tag mb-3">
                  <div className="react-select-container">
                    <label>{T.t('gen_faculty_college_institute')}</label>
                    <Select
                      id='select_faculty'
                      className="react-select"
                      isMulti={false}
                      filterOption={(option: any, query: any) =>
                        option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                      }
                      closeMenuOnSelect={true}
                      options={
                        this.props.selectOptions && this.props.selectOptions.faculties
                          ? this.props.selectOptions.faculties
                          : []
                      }
                      placeholder={T.t('gen_select_faculty_college_institute')}
                      onChange={(option: any) => {
                        this.setState({
                          ...this.state,
                          faculty_id_selected: option && option.value,
                          faculties_selected: option,
                          program_id_selected: 0,
                          programs_selected: {}
                        });
                        this.getProgramsByFacultiesAtActivities([Number(option && option.value)])
                      }}
                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="add-custom-tag mb-3">
                  <div className="react-select-container">
                    <label>{T.t('gen_connected_programs_departments')}</label>
                    <Select
                      id='select_program_department'
                      className="react-select"
                      isDisabled={this.state.faculty_id_selected == undefined || this.state.faculty_id_selected == 0 ? true : false}
                      isMulti={false}
                      filterOption={(option: any, query: any) =>
                        option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                      }
                      closeMenuOnSelect={true}
                      options={this.props.programs_related_faculty}
                      placeholder={T.t('gen_select_program_department')}
                      value={this.state.program_id_selected != 0 ? this.state.programs_selected : null}
                      onChange={(option: any) => {
                        this.setState({
                          ...this.state,
                          program_id_selected: option && option.value,
                          programs_selected: option
                        });
                      }}
                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 form-input form-group with-icon">
                <input
                  id="section"
                  name="section"
                  value={this.state.section}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      ...this.state,
                      section: e.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  type="text"
                />
                <span className="highlight" />
                <span className="bar" />
                <label htmlFor="section">{T.t('gen_section')}</label>
                {sectionFormatValidation && (
                  <div className="error">{T.t('gen_cannot_enter_special_more_than_characters')}</div>
                )}
              </div>
              <div className="col-md-12 mb-3 input-group autocomplete">
                <input
                  type="text"
                  name="valufilter-combined-valueses"
                  className="form-control searchWrapper input-group autocomplete"
                  value={this.state.combined_value_query}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({
                      ...this.state,
                      combined_value_query: event.target.value
                    })
                  }
                  id="filter-combined-values"
                  placeholder={T.t('gen_search')}
                  data-class="fup"
                />
                <div className="input-group-prepend collapsed ml-1 mr-2" aria-expanded="false">
                  <span
                    className="category-tag-square tag-glass pl-0 pr-2"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        combined_value_query: ''
                      });
                    }}
                  >
                    <i data-toggle="tooltip" className="material-icons" data-original-title="Listeden Çıkar">
                      close
                    </i>
                    {T.t('gen_clear')}
                  </span>
                </div>
                <button
                  id='button_delete_circle_outline'
                  className="mss-form-button mr-3"
                  style={{ backgroundColor: '#dc3545', color: "#fff" }}
                  onClick={() => this.onDeleteRow(this.state.selected_ids)}
                >
                  <i className="material-icons pr-1 pl-1">delete_sweep</i>
                </button>
                <button
                  style={{ backgroundColor: '#059700', color: "#fff" }}
                  id='button_add_circle_outline'
                  className="mss-form-button"
                  disabled={
                    ((this.state.program_id_selected != undefined && this.state.program_id_selected != 0) ? false : true)
                    || (this.state.alreadyInTheList ? true : false)
                    || sectionFormatValidation
                  }
                  data-dismiss="modal"
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                </button>
              </div>
              <div className="add-custom-tag mb-3">
                <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table courseformtable">
                  <thead>
                    <tr className="courseformtable-thead-tr">
                      <th data-cell="select" scope="col" style={{ 'width': '7%' }}>
                        <div className="tick-radio d-flex align-items-center justify-content-center mt-2">
                          <input
                            id='select_all'
                            type="checkbox"
                            className="form-radio"
                            checked={this.checkAllIdsSelected()}
                            onChange={this.onSelectAll}
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ 'width': '50%' }}>
                        <span>{T.t('gen_faculty')}</span>
                      </th>
                      <th scope="col" style={{ 'width': '50%' }}>
                        <span>{T.t('gen_program')}</span>
                      </th>
                      <th scope="col" className="text-center" style={{ 'width': '13%' }}>
                        <span>{T.t('gen_section')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.combined_values && this.state.combined_values.length
                      ? this.state.combined_values.map((item: any, index: any) => {
                        return (
                          <tr key={index} data-title={item.faculty_id}>
                            <tr className="courseformtable-tbody-tr">
                              <td className="courseformtable-td" key={index} style={{ 'width': '45px' }}>
                                <div className="tick-radio d-flex align-items-center justify-content-center">
                                  <input
                                    id='select_course'
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.index ? item.index : -1) > -1
                                    }
                                    data-id={item.index}
                                    onChange={this.onSelectCourse}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_faculty')} className="courseformtable-td" style={{ 'width': '50%' }}>
                                {item.faculty.label}
                              </td>
                              <td scope="row" data-label={T.t('gen_program')} className="courseformtable-td" style={{ 'width': '50%' }}>
                                {item.program.label}
                              </td>
                              <td scope="row" data-label={T.t('gen_section')} className="courseformtable-td text-center" style={{ 'width': '13%' }}>
                                {item.section}
                              </td>
                            </tr>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </table>
              </div>
            </div >
          );
        }
        }
      </Formik >
    )
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.select_options) {
    return (
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(MultiSelectionTable);

export default container;
