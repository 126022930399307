import * as Types from '../types';
import Translator from '../../services/translate-factory';

export const constants = {
  ACTIVITY_LIST_SEARCH: 'ACTIVITY:LIST_SEARCH',
  ACTIVITY_CREATE: 'ACTIVITY:CREATE',
  ACTIVITY_UPDATE: 'ACTIVITY:UPDATE',
  ACTIVITY_DELETE: 'ACTIVITY:DELETE',
  ACTIVITY_GET_BY_ID: 'ACTIVITY:GET_BY_ID',
  ACTIVITY_IS_RELATED: 'ACTIVITY:IS_RELATED',
  ACTIVITY_GET_SELECT_OPTIONS: 'ACTIVITY:GET_SELECT_OPTIONS',
  ACTIVITY_CREATE_EXCEL_BULK: 'ACTIVITY:CREATE_EXCEL_BULK',
  ACTIVITY_CHANGE_ALL_DATA: 'ACTIVITY:CHANGE_ALL_DATA',
  ACTIVITY_CREATE_BREAK_COURSES: 'ACTIVITY:CREATE_BREAK_COURSES',
  ACTIVITY_GET_PROGRAMS_BY_FACULTIES: 'ACTIVITY:GET_PROGRAMS_BY_FACULTIES'
};

export const ActivitySearchInitialValues: Types.IFilterActivity = {
  activity_noes_ids: [],
  activity_noes: [],
  course_codes_ids: [],
  course_codes: [],
  course_name: '',
  status: [],
  course_types: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  lecture_locations: undefined,
  lecture_location_ids: undefined,
  practical_locations: undefined,
  practical_location_ids: undefined,
  campuses: undefined,
  campus_ids: undefined,
  practical_staff: [],
  practical_staff_ids: undefined,
  instructors: [],
  instructor_ids: undefined,
  lecture_staff: [],
  lecture_staff_ids: undefined,
  faculties: [],
  faculty_ids: [],
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  lesson_counts: [],
  education_types: [],
  grades: [],
  sections_object: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ChangeAlldataModalInitialValues: Types.IFilterChangeAllDataModal = {
  selected_types: undefined,
  selected_type_ids: undefined,
  lecture_locations: [],
  course_type: [],
  education_type: [],
  status: 0
};

export const ActivityHoursSearchInitialValues: Types.IFilterActivity = {
  activity_noes_ids: [],
  activity_noes: [],
  course_code: '',
  course_name: '',
  status: [1],
  term_id: undefined,
  add_list: [],
  select_all: false,
  lecture_locations: undefined,
  lecture_location_ids: undefined,
  practical_locations: undefined,
  practical_location_ids: undefined,
  campuses: undefined,
  campus_ids: undefined,
  practical_staff: [],
  practical_staff_ids: undefined,
  exam_supervisor: [],
  exam_supervisor_ids: undefined,
  classroom_combine_statuses: undefined,
  classroom_combine_status_ids: undefined,
  instructors: [],
  instructor_ids: undefined,
  lecture_staff: [],
  lecture_staff_ids: undefined,
  faculties: [],
  faculty_ids: [],
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  lesson_counts: [],
  education_types: [],
  grades: [],
  sections_object: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  course_codes: []
};

export const ActivityFormInitialValues: Types.IActivityItem = {
  activity_no: undefined,
  course_code: '',
  status: 1,
  class: undefined,
  group: '',
  course_name: '',
  student_count: undefined,
  section: '',
  activity_type: undefined,
  education_type: undefined,
  lesson_count: undefined,
  week: undefined,
  exam_duration: undefined,
  lecture_duration: undefined,
  exam_session: undefined,
  invigilator_gap_after_exam: undefined,
  invigilator_gap_before_exam: undefined,
  practical_duration: undefined,
  assistant_staff: [],
  programs: [],
  program_ids: [],
  practical_staff: [],
  lecture_staff: [],
  instructors: [],
  instructor_ids: undefined,
  faculty_id: undefined,
  faculty_ids: [],
};

export const ViewOptions: Array<Types.ISelectOption> = [
  { label: 'Sınav Planlama', value: 1 },
  { label: 'Ders Planlama', value: 2 },
  { label: 'Tüm Alanlar', value: 3 }
];

export const ClassroomCombineTypes = (T: Translator) => [
  { label: T.t('gen_combined'), value: 1 },
  { label: T.t('gen_according_to_instructors'), value: 2 },
  { label: T.t('gen_by_programs'), value: 3 }
];

export const EducationTypeSelectOptions = (T: Translator) => [
  { label: T.t('gen_formal_education'), value: 'NO' },
  { label: T.t('gen_secondary_education'), value: 'IO' },
  { label: T.t('gen_distance_learning'), value: 'Uzaktan Eğitim' }
];

export const ActivityTermLectureLocations = (T: Translator) => [
  { label: T.t('gen_external_responsible'), value: 1000 },
  { label: T.t('gen_external_active'), value: 1001 },
  { label: T.t('gen_distance_learning'), value: 1002 }
];

export const CourseTypes = (T: Translator): Array<Types.ISelectOption> => [
  { label: T.t('gen_compulsory'), value: 'Z' },
  { label: T.t('gen_optional'), value: 'S' }
];

export const CourseTypesLabel = (courseTypeValue: string, T: Translator): string => {
  const courseTypeObject = T.t_options(CourseTypes(T)).find(courseType => courseType.value === courseTypeValue);

  return courseTypeObject ? courseTypeObject.label : '-';
}

export const ActivityTypes: Array<Types.ISelectOption> = [
  { label: 'gen_theoric', value: 'Teorik' },
  { label: 'gen_application', value: 'Uygulama' },
  { label: 'gen_laboratory', value: 'Laboratuvar' },
  { label: 'gen_problem_solving', value: 'Problem Çözme' },
  { label: 'gen_internship', value: 'Staj' },
  { label: 'gen_practice', value: 'Pratik' },
  { label: 'gen_search', value: 'Ara' }
];

export const ActivityTypesLabel = (activityTypeValue: string, T: Translator): string => {
  const activityTypeObject = T.t_options(ActivityTypes).find(activityType => activityType.value === activityTypeValue);

  return activityTypeObject ? activityTypeObject.label : '-';
}

export const Weeks: Array<Types.ISelectOption> = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
];

export const DayOfWeek: Array<Types.ISelectOption> = [
  { label: 'gen_monday', value: 1 },
  { label: 'gen_tuesday', value: 2 },
  { label: 'gen_wednesday', value: 3 },
  { label: 'gen_thursday', value: 4 },
  { label: 'gen_friday', value: 5 },
  { label: 'gen_saturday', value: 6 },
  { label: 'gen_sunday', value: 7 }
];

export const CourseTermLectureLocationsForSolution = (T: Translator) => [
  { label: T.t('gen_external_responsible'), value: 1000 },
  { label: T.t('gen_external_active'), value: 1001 },
  { label: T.t('gen_distance_learning'), value: 1002 }
];
