import React from 'react';
import { Log } from 'ng2-logger';
import PlainLoyout from '../layouts/plain-layout';
import imgLogo from '../../assets/logos/favicon.png';
import PasswordForm from './password-form';
import Spinner from '../../components/templates/spinner';
import * as Constants from '../../store/constants/all';
import { connect } from 'react-redux';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

const L = Log.create('ForgotPwPage');
const T = Translator.create();
let url: string = window.location.href;
let token: string = url.substring(url.lastIndexOf('reNewPass?') + 10) || url.substring(url.lastIndexOf('renewpass?') + 10)

class ForgotPwPage extends React.Component<any, any> {

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  render() {
    return (
      <PlainLoyout>
        <div className="login-wrapper">
          <div className="min-container">
            <div className="login-block">
              <Spinner name="login-form-spin" />
              <img className="img-fluid" src={imgLogo} alt="Perculus Plus" />
              <PasswordForm match={this.props.match} code={token} />
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-icons mr-1">language</i>
                  <i className="material-icons ml-3 arrow">unfold_more</i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('tr-TR', true)}>
                    {T.t('gen_lang_tr')}
                  </a>
                  <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('en-EN', true)}>
                    {T.t('gen_lang_en')}
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex login-block-footer">
              <a href="" data-toggle="modal" data-target="#coypright-modal">
                <img src={imgLogo} alt="APlan" />
                <span>2013-2022 © APlan & Advancity</span>
              </a>
            </div>
          </div>
        </div>
      </PlainLoyout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ILoginPageProps) => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    //token: '26WeQVeW6oUG0quKOtcnPDRYvYaAQ1SmbGqUB8VZNdQ='
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(ForgotPwPage);

export default container;
