import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';


export const FacultyFormValidation = (T: Translator) => Yup.object().shape({
  faculty_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  campus_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
});
