import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const ProgramFormValidation = (T: Translator, term_id: number) => Yup.object().shape({
  program_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  faculty_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  daily_max_class: Yup.number().required(T.t('gen_cannot_leave_empty')),
  education_type: Yup.string().required(T.t('gen_cannot_leave_empty')),
  min_class_interval_bw_campuses: Yup.number().required(T.t('gen_cannot_leave_empty')),
  grade_ids: term_id === 0 ? (Yup.string().when('grade', {
    is: (grade: number) => (grade === 0),
    then: Yup.string().required(T.t('gen_cannot_leave_empty')),
    otherwise: Yup.string().notRequired()
  })) : Yup.string().notRequired(),
  max_gap_between_courses: term_id === 0 ? Yup.number().min(1, T.t('gen_can_be_at_least_n').replace('{0}', '1')).max(7, T.t('gen_can_be_up_to_n').replace('{0}', '7')) : Yup.number().notRequired(),
  description: Yup.string().max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
});
