import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { SyncCampusSearchInitialValues } from '../../store/constants/sync-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import Translator from '../../services/translate-factory';
import { SyncResultIds } from '../../store/constants/enums';
import { SectionTypes } from '../../store/constants/enums';
import DownloadButton from '../../components/excel-imports/export';

const T = Translator.create();
const L = Log.create('GroupListPage');

class CampusSyncPage extends Component<Types.ISyncCampusPageProps, Types.ISyncCampusPageState> {
    state: Types.ISyncCampusPageState = {
        filters: SyncCampusSearchInitialValues,
        filterIsOpen: false,
        campusFormIsOpen: false,
        integrationModalIsOpen: false,
        all_ids: [],
        selected_ids: [],
        sync_job_id: undefined,
        selected_codes: []
    };

    langChanged = () => {
        setTimeout(() => {
            try {
                this.forceUpdate();
            } catch (e) {
                L.error(e);
            }
        }, 1000);
    };

    componentDidMount() {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
        window.scrollTo(0, 0);
        this.searchCampuses();
        this.getCampusSelectOptions();
    }

    componentWillUnmount() {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        /*setTimeout(() => {
            const SyncStatusModel = {
                sync_job_id: this.props.synchronization && this.props.synchronization.sync_job_id,
                campus_ids: this.state.selected_ids
            }
            this.props.dispatch(Actions.ClearStatus());
            this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CAMPUS_ADD_TO_MAIN, SyncStatusModel, 'sync-form-spin'));
        }, 2000)*/

        /*setTimeout(() => {
            //if (this.props.synchronization && prevProps.synchronization.result != this.props.synchronization.result) {
            const SyncStatusModel = {
                sync_job_id: this.props.synchronization && this.props.synchronization.sync_job_id,
                campus_ids: this.state.selected_ids
            }
            this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, SyncStatusModel, 'sync-form-spin'));
            //}
            //timer == null;
        }, 4000)
        //clearTimeout()
        if (this.props.synchronization && (this.props.synchronization.result_id == 5)) {
            this.props.dispatch(Actions.Notification('Seçtiğiniz kampüsler eklendi.', 'gen_success'))
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS)))
        } else if (this.props.synchronization && this.props.synchronization.result && this.props.synchronization.result_id == 6) {
            this.props.dispatch(Actions.Notification('Seçtiğiniz kampüsler daha önce senkronize edildi.', 'gen_warning', 'danger'))
        }*/
    }

    searchCampuses() {
        this.props.dispatch(
            Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CAMPUS_LIST_SEARCH, this.state.filters, 'campus-list-spin')
        );
    }

    getCampusSelectOptions() {
        this.props.dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, 'campus-list-spin'));
    }

    sort = (sortkey: string, order_by: string) => {
        this.state.filters.order_by = sortkey + '_' + order_by;
        this.setState(this.state);
        this.searchCampuses();
    };

    campusFormIsOpen = () => {
        this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS, '/create')));
    };

    campusFormOnClose = (refresh: boolean) => {
        if (this.state.campusFormIsOpen) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS)));
        }
        if (refresh) {
            this.searchCampuses();
        }
    };

    onPageChange = (page: number) => {
        this.state.filters.page = page;
        this.setState(this.state);
        this.searchCampuses();
    };

    onFormReset = () => {
        this.state.filters = Object.assign({}, SyncCampusSearchInitialValues);
        this.setState(this.state);
        this.searchCampuses();
    };

    onFilterCampus(model: Types.IFilterSyncCampus, FormActions: FormikActions<Types.IFilterSyncCampus>) {
        this.state.filters = model;
        this.state.filters.page = 1;
        this.state.filterIsOpen = true;
        this.setState(this.state);
        this.searchCampuses();
        FormActions.setSubmitting(false);
    }

    onCampusEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e && e.currentTarget) {
            const id: string = e.currentTarget.dataset.id || '';
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS, '/' + id)));
        }
    };

    onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.currentTarget) {
            if (e.currentTarget.checked) {
                this.state.filters.select_all = true;
                this.setState(this.state);
                this.props.dispatch(
                    Actions.ApiRequest(
                        Constants.synchronization.SYNCHRONIZATION_CAMPUS_LIST_SEARCH,
                        this.state.filters,
                        'campus-list-spin',
                        (response: any) =>
                            this.setState({
                                ...this.state,
                                all_ids: response.all_ids,
                                selected_ids: response.all_ids
                            })
                    )
                );
            } else {
                this.setState({
                    ...this.state,
                    all_ids: [],
                    selected_ids: [],
                    filters: {
                        ...this.state.filters,
                        select_all: false
                    }
                });
            }
        }
    };

    checkAllIdsSelected = (): boolean => {
        const all_ids = this.state.all_ids ? this.state.all_ids : [];
        const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
        let result: boolean = false;
        if (all_ids.length && selected_ids.length) {
            result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
        }
        return result;
    };

    onAddCampus = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e && e.target) {
            this.props.dispatch(
                Actions.ShowModal({
                    title: T.t('gen_campus_add'),
                    body: T.t('gen_add_campus_question'),
                    name: 'campus_add',
                    icon: 'warning',
                    iconColor: 'green',
                    confirm: T.t('gen_yes'),
                    cancel: T.t('gen_cancel'),
                    onConfirm: () => {
                        const SyncStatusModel = {
                            sync_job_id: this.props.sync_job_id,
                            campus_ids: this.state.selected_ids
                        }
                        this.props.dispatch(Actions.ClearStatus());
                        this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CAMPUS_ADD_TO_MAIN, SyncStatusModel, 'sync-form-spin'));
                        this.props.dispatch(Actions.Notification(T.t('gen_trying_to_transfer_data'), T.t('gen_please_wait'), 'warning'));
                        setTimeout(() => {
                            this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, SyncStatusModel, 'sync-form-spin'));
                            setTimeout(() => {
                                if (this.props.synchronization && (this.props.synchronization.result_id == SyncResultIds.Synched)) {
                                    this.props.dispatch(Actions.Notification(T.t('gen_campuses_added'), T.t('gen_success')))
                                    this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS)))
                                } else if (this.props.synchronization && this.props.synchronization.result_id == SyncResultIds.SyncFailed) {
                                    this.props.dispatch(Actions.Notification(T.t('gen_campuses_synchronized_before'), T.t('gen_warning'), 'danger'))
                                }
                            }, 1500)
                        }, 1000)
                    }
                })
            );
        }
    };

    onSelectCampus = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.currentTarget) {
            let checkedList = Object.assign([], this.state.selected_ids);
            let stringID: string = e.currentTarget.dataset.id || '';
            let id = parseInt(stringID, 10);

            if (e.target.checked) {
                checkedList.push(id);
            } else {
                let index = checkedList.indexOf(id);
                if (index !== -1) {
                    checkedList.splice(index, 1);
                }
                this.setState({
                    ...this.state,
                    filters: {
                        ...this.state.filters,
                        select_all: false
                    }
                });
            }
            this.setState({
                ...this.state,
                selected_ids: checkedList
            });
        }
    };

    switchIntegrationModalStatus = () => {
        this.setState({
            ...this.state,
            integrationModalIsOpen: !this.state.integrationModalIsOpen
        });
    };

    static getDerivedStateFromProps(props: Types.ISyncCampusPageProps, state: Types.ISyncCampusPageState) {
        let hasNewState: boolean = false;
        if (props.match && props.match.params.id) {
            hasNewState = true;
            state.campusFormIsOpen = true;
            if (props.match.params.id !== 'create') {
                state.campusId = props.match.params.id;
            } else {
                state.campusId = undefined;
            }
        } else {
            hasNewState = true;
            state.campusFormIsOpen = false;
            state.campusId = undefined;
        }

        if (hasNewState) {
            return state;
        } else {
            return null;
        }
    }

    render() {
        let campusList = this.props.results;
        return (
            <MainLayout header={<APlanHeader />}>
                <SimplePage name="building-page">
                    <div className="main list-campus">
                        <div className="container-fluid">
                            <h4 className="mb-4 mb-sm-0">{T.t('gen_campus_transfer')}</h4>
                            <Spinner name="building-list-spin" />
                            <div className="white-container mt-4">
                                <div className="row align-items-center mb-1">
                                    <div className="col-md-6 col-sm-4 col-12">
                                        {this.state.selected_ids && this.state.selected_ids.length ? (
                                            <React.Fragment>
                                                <button
                                                    className="category-tag-square tag-green pr-2 pl-2"
                                                    style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                                                    onClick={this.onAddCampus}
                                                >
                                                    <i className="material-icons mr-1">add</i>
                                                    <span>{T.t('gen_save_selection')}</span>
                                                </button>
                                                <button
                                                    className="category-tag-square tag-gray float-rigth pr-2 pl-2"
                                                    style={{ margin: '5px' }}
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            selected_ids: []
                                                        });
                                                    }}
                                                >
                                                    <i className="material-icons mr-2">close</i>
                                                    <span>
                                                        {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                                                    </span>
                                                </button>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <button
                                                    className="category-tag-square tag-gray pr-2 pl-2"
                                                    style={{ margin: '5px', cursor: 'not-allowed' }}
                                                    disabled={true}
                                                >
                                                    <i className="material-icons mr-1">add</i>
                                                    <span>{T.t('gen_save_selection')}</span>
                                                </button>
                                            </React.Fragment>
                                        )}
                                        <DownloadButton title={'SyncCampuses'} sectionType={SectionTypes.SyncCampuses} />
                                    </div>
                                    <div className="col-md-6 col-sm-8 col-12 text-right">
                                        <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                                            <button
                                                className="category-tag-square tag-glass float-right ml-3 mr-3"
                                                style={{ margin: '5px' }}
                                                onClick={() => {
                                                    this.state.filterIsOpen = !this.state.filterIsOpen;
                                                    this.setState(this.state);
                                                }}
                                            >
                                                <i className="material-icons mr-2">filter_list</i>
                                                <span>{T.t('gen_filter')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* FILTER STARTS HERE  */}
                                <div
                                    className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                                    id="advance-search"
                                >
                                    <div className="advance-search d-block mt-3" style={{}}>
                                        <Formik
                                            initialValues={SyncCampusSearchInitialValues}
                                            enableReinitialize={true}
                                            onSubmit={(values, actions) => {
                                                this.onFilterCampus(values, actions);
                                            }}
                                            onReset={this.onFormReset}
                                        >
                                            {(props: FormikProps<Types.IFilterSyncCampus>) => {
                                                return (
                                                    <form onSubmit={props.handleSubmit}>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h6>{T.t('gen_filter_parameters')}</h6>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-input form-group date-picker">
                                                                    <input
                                                                        id="campus_code"
                                                                        name="campus_code"
                                                                        value={props.values.campus_code}
                                                                        onChange={props.handleChange}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                    <span className="highlight" />
                                                                    <span className="bar" />
                                                                    <label htmlFor="campus_code">{T.t('gen_code')}</label>
                                                                    <i className="material-icons">filter_1</i>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-input form-group date-picker">
                                                                    <input
                                                                        id="name"
                                                                        name="name"
                                                                        value={props.values.name}
                                                                        onChange={props.handleChange}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                    <span className="highlight" />
                                                                    <span className="bar" />
                                                                    <label htmlFor="name">{T.t('gen_name')}</label>
                                                                    <i className="material-icons">title</i>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-input form-group date-picker">
                                                                    <input
                                                                        id="address"
                                                                        name="address"
                                                                        value={props.values.address}
                                                                        onChange={props.handleChange}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                    <span className="highlight" />
                                                                    <span className="bar" />
                                                                    <label htmlFor="address">{T.t('gen_address')}</label>
                                                                    <i className="material-icons">title</i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mt-3">
                                                            <div className="col-6">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.state.filterIsOpen = false;
                                                                        this.setState(this.state);
                                                                    }}
                                                                    className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                                                >
                                                                    <i className="material-icons">arrow_upward</i>
                                                                </button>
                                                                <button
                                                                    type="reset"
                                                                    onClick={props.handleReset}
                                                                    className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                                                >
                                                                    <i className="material-icons">delete_sweep</i>
                                                                </button>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                                                    onClick={() => props.handleSubmit()}
                                                                    disabled={props.isSubmitting}
                                                                >
                                                                    <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                                {/* TODO: TABLE STARTS HERE  */}
                                <div className="row">
                                    <div className="col-12">
                                        <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                            <thead>
                                                <tr>
                                                    <th data-cell="select">
                                                        <div className="tick-radio position-relative">
                                                            <input
                                                                type="checkbox"
                                                                className="form-radio"
                                                                checked={this.checkAllIdsSelected()}
                                                                onChange={this.onSelectAll}
                                                            />
                                                        </div>
                                                    </th>
                                                    <SortedColumn
                                                        datacell="campus_code"
                                                        title={T.t('gen_code')}
                                                        sortkey="campus_code"
                                                        sortedcolumn={this.state.filters.order_by}
                                                        sort={this.sort}
                                                    />
                                                    <SortedColumn
                                                        datacell="name"
                                                        className="d-none d-lg-table-cell d-xl-table-cell"
                                                        title={T.t('gen_name')}
                                                        sortkey="name"
                                                        sortedcolumn={this.state.filters.order_by}
                                                        sort={this.sort}
                                                    />
                                                    <SortedColumn
                                                        datacell="address"
                                                        className="d-none d-lg-table-cell d-xl-table-cell"
                                                        title={T.t('gen_address')}
                                                        sortkey="address"
                                                        sortedcolumn={this.state.filters.order_by}
                                                        sort={this.sort}
                                                    />
                                                    <SortedColumn
                                                        datacell="created_date"
                                                        className="d-none d-lg-table-cell d-xl-table-cell"
                                                        title={T.t('gen_create_date')}
                                                        sortkey="created_date"
                                                        sortedcolumn={this.state.filters.order_by}
                                                        sort={this.sort}
                                                    />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {campusList && campusList.length
                                                    ? campusList.map((item: Types.ISyncCampusItem) => (
                                                        <tr
                                                            key={'campus-1' + item.sync_campus_id}
                                                            data-title={item && item.campus_code + ' / ' + item.name}
                                                        >
                                                            <td data-cell="select">
                                                                <div className="tick-radio position-relative">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-radio"
                                                                        checked={
                                                                            this.state.selected_ids &&
                                                                            this.state.selected_ids.indexOf(item.sync_campus_id ? item.sync_campus_id : -1) > -1
                                                                        }
                                                                        data-id={item.sync_campus_id}
                                                                        onChange={this.onSelectCampus}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td scope="row" data-label={T.t('gen_code')}>
                                                                {item.campus_code}
                                                            </td>
                                                            <td data-label={T.t('gen_name')}>{item.name}</td>
                                                            <td data-label={T.t('gen_address')}>{item.address}</td>
                                                            <td data-label={T.t('gen_create_date')}>{item.created_date}</td>
                                                        </tr>
                                                    ))
                                                    : null}
                                            </tbody>
                                        </table>
                                        <div className="row-options justify-content-end">
                                            <div
                                                className="page-sorting d-flex align-items-center justify-content-center"
                                                style={{ marginTop: '5px' }}
                                            >
                                                {this.props.results && this.props.results.length > 0 && (
                                                    <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SimplePage>
            </MainLayout>
        );
    }
}

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: Types.ISyncCampusPageProps
): Types.ISyncCampusPageProps => {
    if (!store) {
        return ownProps;
    }
    const newProps: Types.ISyncCampusPageProps = Object.assign({}, ownProps, {
        results: store.state.sync_campus_page && store.state.sync_campus_page.results,
        sync_job_id: store.state.sync_campus_page && store.state.sync_campus_page.sync_job_id,
        filters: store.state.sync_campus_page && store.state.sync_campus_page.filters,
        selectOptions: store.state.select_options && store.state.select_options.campusPage,
        synchronization: store.state.synchronization && store.state.synchronization
    });
    return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    let nextResults =
        next.state.sync_campus_page && next.state.sync_campus_page.results ? next.state.sync_campus_page.results : [];
    let prevResults =
        prev.state.sync_campus_page && prev.state.sync_campus_page.results ? prev.state.sync_campus_page.results : [];
    if (nextResults) {
        return !!equal(prevResults, nextResults);
    } else {
        return true;
    }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
    areStatesEqual
})(CampusSyncPage);

export default container;
