import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import { SyncCourseSearchInitialValues } from '../../../store/constants/sync-const';
import { routes as Routes } from '../../../store/constants/routes';
import * as GT from '../../../tools/general-tools';
import Spinner from '../../../components/templates/spinner';
import MainLayout from '../../layouts/main-layout';
import SimplePage from '../../../components/templates/simple-page';
import APlanHeader from '../../../components/templates/aplan-header';
import SortedColumn from '../../../components/table/sorted-column';
import Paginate from '../../../components/table/paginate';
import Translator from '../../../services/translate-factory';
import { SyncResultIds } from '../../../store/constants/enums';
import { EducationTypeSelectOptions } from '../../../store/constants/course-const';
import { SectionTypes } from '../../../store/constants/enums';
import DownloadButton from '../../../components/excel-imports/export';

const T = Translator.create();
const L = Log.create('GroupListPage');

class CourseSyncPage extends Component<Types.ISyncCoursePageProps, Types.ISyncCoursePageState> {
  state: Types.ISyncCoursePageState = {
    filters: SyncCourseSearchInitialValues,
    filterIsOpen: false,
    courseFormIsOpen: false,
    integrationModalIsOpen: false,
    all_ids: [],
    selected_ids: [],
    sync_job_id: undefined,
    countCalled: 0
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.searchCourses();
    this.getCourseSelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const SyncStatusModel = {
      sync_job_id: this.props.sync_job_id,
      program_ids: this.state.selected_ids
    }
    setTimeout(() => {
      if (this.props.synchronization && this.props.synchronization.result_id !== undefined) {
        if (this.props.synchronization && (this.props.synchronization.result_id == SyncResultIds.Synched)) {
          this.props.dispatch(Actions.Notification(T.t('gen_courses_added'), T.t('gen_success')))
          this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE)))
        } else if (this.props.synchronization && this.props.synchronization.result_id == SyncResultIds.SyncFailed) {
          this.props.dispatch(Actions.Notification(T.t('gen_courses_synchronized_before'), T.t('gen_warning'), 'danger'))
        } else if (this.props.synchronization && this.props.synchronization.result_id == SyncResultIds.SyncStarted) {
          if (this.state.countCalled! < 5) {
            setTimeout(() => {
              this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, SyncStatusModel, 'sync-form-spin'));
            }, 1000)
            this.setState({
              countCalled: this.state.countCalled! + 1
            })
          }
          if (this.state.countCalled! < 1) {
            this.props.dispatch(Actions.Notification(T.t('gen_error_occurred'), T.t('gen_warning'), 'danger'))
          }
        }
        this.props.dispatch(Actions.ClearStatus());
      }
    }, 1500)
  }

  searchCourses() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_COURSE_LIST_SEARCH, this.state.filters, 'course-list-spin')
    );
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchCourses();
  };

  courseFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE, '/create')));
  };

  courseFormOnClose = (refresh: boolean) => {
    if (this.state.courseFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE)));
    }
    if (refresh) {
      this.searchCourses();
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchCourses();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, SyncCourseSearchInitialValues);
    this.setState(this.state);
    this.searchCourses();
  };

  onFilterCourse(model: Types.IFilterSyncCourse, FormActions: FormikActions<Types.IFilterSyncCourse>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchCourses();
    FormActions.setSubmitting(false);
  }

  onCourseEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.synchronization.SYNCHRONIZATION_COURSE_LIST_SEARCH,
            this.state.filters,
            'course-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onAddCourse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_course_add'),
          body: T.t('gen_add_course_question'),
          name: 'course_add',
          icon: 'warning',
          iconColor: 'green',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const SyncStatusModel = {
              sync_job_id: this.props.sync_job_id,
              program_ids: this.state.selected_ids
            }
            this.props.dispatch(Actions.ClearStatus());
            this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_COURSE_ADD_TO_MAIN, SyncStatusModel, 'sync-form-spin'));
            this.props.dispatch(Actions.Notification(T.t('gen_trying_to_transfer_data'), T.t('gen_please_wait'), 'warning'));
            this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, SyncStatusModel, 'sync-form-spin'));
          }
        })
      );
    }
  };

  onSelectCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.ISyncCoursePageProps, state: Types.ISyncCoursePageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.courseFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.courseId = props.match.params.id;
      } else {
        state.courseId = undefined;
      }
    } else {
      hasNewState = true;
      state.courseFormIsOpen = false;
      state.courseId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let programList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="building-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_course_transfer')}</h4>
              <Spinner name="course-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-6 col-sm-4 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-green pr-2 pl-2"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onAddCourse}
                        >
                          <i className="material-icons mr-1">add</i>
                          <span>{T.t('gen_save_selection')}</span>
                        </button>
                        <button
                          className="category-tag-square tag-gray float-rigth pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-1">add</i>
                          <span>{T.t('gen_save_selection')}</span>
                        </button>
                      </React.Fragment>
                    )}
                    <DownloadButton title={'SyncCourses'} sectionType={SectionTypes.SyncCourses} />
                  </div>
                  <div className="col-md-6 col-sm-8 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3" style={{}}>
                    <Formik
                      initialValues={SyncCourseSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterCourse(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterSyncCourse>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_code"
                                    name="course_code"
                                    value={props.values.course_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="course_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                {/* TODO: TABLE STARTS HERE  */}
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="course_code"
                            title={T.t('gen_code')}
                            sortkey="course_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="activity_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_activity_type')}
                            sortkey="education_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="education_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_education_type')}
                            sortkey="education_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="term"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_term')}
                            sortkey="term"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="department_code"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_course_program_code')}
                            sortkey="department_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="class"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_grade')}
                            sortkey="class"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="group"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_section')}
                            sortkey="group"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="faculty_name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_faculty')}
                            sortkey="faculty_name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="lecture_staff_codes"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_lecture_staff')}
                            sortkey="lecture_staff_codes"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="student_count"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_student_count')}
                            sortkey="student_count"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="created_date"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_create_date')}
                            sortkey="created_date"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {programList && programList.length
                          ? programList.map((item: Types.ISyncCourseItem) => (
                            <tr
                              key={'building-1' + item.sync_course_id}
                              data-title={item.program && item.program.label + ' / ' + item.name}
                            >
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.sync_course_id ? item.sync_course_id : -1) > -1
                                    }
                                    data-id={item.sync_course_id}
                                    onChange={this.onSelectCourse}
                                  />
                                </div>
                              </td>
                              <td data-label={T.t('gen_status')}>
                                <button
                                  className={
                                    `small-tag text-uppercase` + (item.status == 'True' ? ` tag-green` : ` tag-red`)
                                  }
                                >
                                  {item.status == 'True' ? T.t('gen_active_upp') : T.t('gen_passive_upp')}
                                </button>
                              </td>
                              <td data-label={T.t('gen_code')}>{item.course_code}</td>
                              <td data-label={T.t('gen_name')}>{item.name}</td>
                              <td data-label={T.t('gen_activity_type')}>{item.activity_type}</td>
                              <td scope="row" data-label={T.t('gen_education_type')}>
                                {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                  EducationTypeSelectOptions(T)[0].label :
                                  item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                    EducationTypeSelectOptions(T)[1].label :
                                    item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                      EducationTypeSelectOptions(T)[2].label : item.education_type
                                }
                              </td>
                              <td data-label={T.t('gen_term')}>{item.term}</td>
                              <td className="td-centered" data-label={T.t('gen_course_program_code')}>{item.department_code}</td>
                              <td className="td-centered" data-label={T.t('gen_grade')}>{item.class}</td>
                              <td className="td-centered" data-label={T.t('gen_section')}>{item.group}</td>
                              <td className="td-centered" data-label={T.t('gen_faculty_code')}>{item.faculty_code}</td>
                              <td className="td-centered" data-label={T.t('gen_lecture_staff')}>{item.lecture_staff_codes}</td>
                              <td className="td-centered" data-label={T.t('gen_student_count')}>{item.student_count}</td>
                              <td data-label={T.t('gen_create_date')}>{item.created_date}</td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
      </MainLayout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ISyncCoursePageProps
): Types.ISyncCoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ISyncCoursePageProps = Object.assign({}, ownProps, {
    results: store.state.sync_course_page && store.state.sync_course_page.results,
    sync_job_id: store.state.sync_course_page && store.state.sync_course_page.sync_job_id,
    filters: store.state.sync_course_page && store.state.sync_course_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    synchronization: store.state.synchronization && store.state.synchronization
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  let nextResults =
    next.state.sync_course_page && next.state.sync_course_page.results ? next.state.sync_course_page.results : [];
  let prevResults =
    prev.state.sync_course_page && prev.state.sync_course_page.results ? prev.state.sync_course_page.results : [];
  if (nextResults) {
    return !!equal(prevResults, nextResults);
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseSyncPage);

export default container;
