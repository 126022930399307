import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { DistributorModalSearchInitialValues, DistributorJobStatus } from '../../store/constants/distributor-const';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import ChangeSolutionModal from './solution-change-modal';
import { EventSourcePolyfill } from 'event-source-polyfill';
import Timer from '../../components/datetime/timer-memo';

const T = Translator.create();
const L = Log.create('DistributorModal');

class DistributorModal extends Component<any, any> {
  state: Types.IDistributorModalState = {
    filters: DistributorModalSearchInitialValues,
    statusTableIsOpen: false,
    filterIsOpen: false,
    statusHistoryList: [],
    changeSolutionModalIsOpen: false,
    solutionResultIsOpen: false,
    page: 1,
    total: -1,
    size: 10,
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;

    this.CreateSSE();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.CloseSSE();
  }

  private CreateSSE() {
    let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;
    var path = window.location.origin == "http://localhost:3000" ? "http://localhost:2900" : window.location.origin + "/api";
    const eventSourceLocal = new EventSourcePolyfill(path + "/distribution/" + job_id, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'text/event-stream',
        'Cache-Control': 'no-cache',
        'page': '' + this.state.page,
      }
    }) as EventSource;

    this.setState({
      ...this.state,
      eventSource: eventSourceLocal
    });

    eventSourceLocal.addEventListener('inventory', (e: any) => {
      if (e.data) {
        const jobInventory: Types.ISSEInventory = JSON.parse(e.data);
        if (jobInventory) {
          let DistributionStopStatus: Array<number> = [
            DistributorJobStatus.Stopped,
          ];

          if (DistributionStopStatus.includes(jobInventory.status)) {
            this.CloseSSE();
          }

          this.setState({
            ...this.state,
            statusHistoryList: jobInventory.status_history_list && jobInventory.status_history_list.length == 0 ? this.state.statusHistoryList : jobInventory.status_history_list,
            statusHistory: jobInventory.status_history == null ? this.state.statusHistory : jobInventory.status_history,
            status: jobInventory.status,
            total: jobInventory.total_errors,
            page: jobInventory.page,
            created_date: jobInventory.created_date.split('.')[2].split(' ')[0] + '-' + jobInventory.created_date.split('.')[0] + '-' + jobInventory.created_date.split('.')[1] + ' ' + jobInventory.created_date.split(' ')[1],
          });
        }
      }
    }, false);
  }

  private CloseSSE() {
    const eventSourceLocal = (this.state.eventSource as EventSource);
    if (!!this.state.eventSource) {
      eventSourceLocal.close();
    }
  }

  getDistributions() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_DISTRIBUTION_LIST, this.state.filters, 'ep-student-list')
    );
  }

  getDistributionInProgressStatus() {
    if (!!this.props.term_id && this.props.term_id != -1) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.disributor.GET_DISTRIBUTION_IN_PROGRESS_STATUS, this.props.term_id, 'ep-student-list')
      );
    }
  }

  onStopDistribution = () => {
    if (true) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_stop_action'),
          content: (
            <div>
              <p> {T.t('gen_distribution_stop_question')} </p>
            </div>
          ),
          name: 'distribution_stop',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: any, status: number) => {
              if (status === 200) {
                this.getDistributionInProgressStatus();
                this.setCloseModal();
              }
            };
            let job_id = !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(
              Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CANCEL_DISTRIBUTION, job_id, 'distributor-cancel-spin', resultCallback)
            );
          }
        })
      );
    } else {
      this.setCloseModal();
    }
  };

  onSolutionChange = () => {
    // if () {
    //   this.props.dispatch(
    //     Actions.ShowModal({
    //       title: T.t('gen_edit'),
    //       body: T.t("gen_solution_process_info"),
    //       name: 'solution_edit',
    //       icon: 'error_outline',
    //       iconColor: 'red',
    //       cancel: T.t('gen_close')
    //     })
    //   );
    // } else {
    this.switchChangeSolutionModalStatus();
    //}
  }

  switchChangeSolutionModalStatus = () => {
    this.setState({
      ...this.state,
      changeSolutionModalIsOpen: !this.state.changeSolutionModalIsOpen
    });
  };

  // onSolutionResults = () => {
  //   if () {
  //     this.props.dispatch(
  //       Actions.ShowModal({
  //         title: T.t('gen_edit'),
  //         body: T.t("gen_solution_process_info"),
  //         name: 'solution_edit',
  //         icon: 'error_outline',
  //         iconColor: 'red',
  //         cancel: T.t('gen_close')
  //       })
  //     );
  //   } else {
  //   this.switchSolutionResultsModalStatus();
  //   }
  // }

  // switchSolutionResultsModalStatus = () => {
  //   this.setState({
  //     ...this.state,
  //     solutionResultIsOpen: !this.state.solutionResultIsOpen
  //   });
  // };

  showInfoModalStatusHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_all_info'),
          body: T.t('gen_download_all_question'),
          name: 'download_status_history',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.term_id,
              sectionType: 32,
              sectionId: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              parameters: []
            };
            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  onFilterDistributionList(model: Types.IFilterDistributorModal, FormActions: FormikActions<Types.IFilterDistributorModal>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getDistributions();
    FormActions.setSubmitting(false);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getDistributions();
  };

  onPageChange = (page: number) => {
    this.state.page = page;
    this.setState({
      ...this.state,
      page: page
    });

    this.CloseSSE();
    this.CreateSSE();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorModalSearchInitialValues);
    this.setState(this.state);
    this.getDistributions();
  };

  static getDerivedStateFromProps(props: Types.IDistributorPageProps, state: Types.IDistributorPageState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let statusHistoryList = this.state && this.state.statusHistoryList;
    let statusHistoryMessage = this.state && this.state.statusHistory && this.state.statusHistory.message && this.state.statusHistory.message.errorMessage ?
      this.state && this.state.statusHistory && this.state.statusHistory.message && this.state.statusHistory.message.errorMessage : T.t('gen_data_preparation_pending')

    let CompatibilitiesAndButtons = (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-1 col-sm-12 form-input form-group with-icon">
              <input
                id="statistical_compatibility"
                name="statistical_compatibility"
                type="text"
                value={"98.5"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value;
                  this.setState({
                    ...this.state,
                    statistical_compatibility: newValue
                  });
                }}
              />
              <label htmlFor="status_log">{T.t('gen_statistical_compatibility')}</label>
            </div>
            <div className="col-md-1 col-sm-12 form-input form-group with-icon">
              <input
                id="best_compatibility"
                name="best_compatibility"
                type="text"
                value={"98.5"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value;
                  this.setState({
                    ...this.state,
                    best_compatibility: newValue
                  });
                }}
              />
              <label htmlFor="status_log">{T.t('gen_best_compatibility')}</label>
            </div>
            <div className="col-md-1 col-sm-12 form-input form-group with-icon">
              <input
                id="average_compatibility"
                name="average_compatibility"
                type="text"
                value={"98.5"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value;
                  this.setState({
                    ...this.state,
                    average_compatibility: newValue
                  });
                }}
              />
              <label htmlFor="status_log">{T.t('gen_average_compatibility')}</label>
            </div>
            <div className="col-md-9">
              {/* <button
                id='submit_solution'
                className="category-tag-square tag-green float-right pr-2 pl-2 ml-3"
                style={{ margin: "15px 0px 0px 0px" }}
              >
                <i className="material-icons mr-2">done</i>
                <span>{T.t('gen_submit_solution')}</span>
              </button> */}
              {/* <button
                id='change_invigilator'
                className="category-tag-square float-right tag-orange pr-2 pl-2 ml-3"
                style={{ margin: "15px 0px 0px 0px" }}
                onClick={this.onDeleteClassroom}
              >
                <i className="material-icons mr-2">edit</i>
                <span> {T.t('gen_change_invigilator') + ' (' + T.t('gen_automatic') + ')'}</span>
              </button> */}
              {/* <button
                id='change_results'
                className="category-tag-square float-right tag-orange pr-2 pl-2 ml-3 "
                style={{ margin: "15px 0px 0px 0px" }}
                onClick={this.onSolutionResults}
              >
                <i className="material-icons mr-2">edit</i>
                <span> {T.t('gen_change_results')}</span>
              </button> */}
              <button
                id='change_solution'
                className="category-tag-square float-right tag-orange pr-2 pl-2 ml-3 "
                style={{ margin: "15px 0px 0px 0px" }}
                onClick={this.onSolutionChange}
              >
                <i className="material-icons mr-2">edit</i>
                <span> {T.t('gen_change_solution')}</span>
              </button>
            </div>
          </div>
        </div>
      </div >
    );

    let StatusBar = (
      <div className="row">
        <div className="col-md-10 col-sm-12 mt-2">
          <span className="badge badge-secondary" style={{ fontSize: '95%', textAlign: 'center' }}>
            {statusHistoryMessage}
          </span>
        </div>
        <div className="col-md-2 col-sm-12">
          <button
            id='button_status_history'
            className="category-tag-square tag-glass float-right ml-3 mr-3 text-fit-1"
            style={{ margin: '5px' }}
            onClick={() => {
              this.state.statusTableIsOpen = !this.state.statusTableIsOpen;
              this.setState(this.state);
            }}
          >
            <i className="material-icons mr-2">filter_list</i>
            <span>{T.t('gen_status_history')}</span>
          </button>
        </div>
      </div>
    );

    let Table = (
      <div className={`row collapse ` + (this.state.statusTableIsOpen ? `show` : ``)}>
        <div className="col-12">
          <div className="quick-actions">
            <hr />
            <div className="row">
              <div className="col-6 mt-1">
                <h6>{T.t('gen_status_history')}</h6>
              </div>

              <div className="col-6 mt-1">
                <button
                  id='button_filter'
                  className="category-tag-square tag-glass float-right ml-3 mr-3"
                  onClick={() => {
                    this.state.filterIsOpen = !this.state.filterIsOpen;
                    this.setState(this.state);
                  }}
                >
                  <i className="material-icons mr-2">filter_list</i>
                  <span>{T.t('gen_filter')}</span>
                </button>
                <button
                  id='button_list_sync'
                  className="category-tag-square tag-blue float-right ml-3 mr-3"
                  onClick={this.showInfoModalStatusHistory}>
                  <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                  <span>{T.t('gen_download')}</span>
                </button>
              </div>
            </div>
          </div>
        </div >
        <div className={`white-container mt-1 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={DistributorModalSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                values.term_id = this.state.filters.term_id;
                this.onFilterDistributionList(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterStudent>) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upward'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>
              <tr>
                <th scope="col">
                  {T.t('gen_number')}
                </th>
                <SortedColumn
                  datacell="message"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_message')}
                  sortkey="message"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="time"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_date') + ' ' + T.t('gen_time')}
                  sortkey="time"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <th scope="col" className="text-center">
                  {T.t('gen_actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {statusHistoryList && statusHistoryList.length ? (
                statusHistoryList.map((item: any) => {
                  let statusHistoryId = item.id ? item.id : -1;
                  let formattedErrorMessages = item.message.formattedErrorMessage ? item.message.formattedErrorMessage : []
                  return (
                    <tr key={'status-history-' + statusHistoryId} data-title={item.distribution_number}>
                      <td style={{ width: '5%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_number')}>
                        {item.id}
                      </td>
                      <td data-label={T.t('gen_message')}>
                        {item.message.errorMessage}
                        {formattedErrorMessages && formattedErrorMessages.length ?
                          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {T.t('gen_number')}
                                </th>
                                <th className="text-center" scope="col">
                                  {T.t('gen_details')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formattedErrorMessages.map((item: any, index: any) => {
                                return (
                                  <tr key={'status-history-detail-' + index}>
                                    <td style={{ width: '5%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_number')}>
                                      {index}
                                    </td>
                                    <td className="table-scrollable-td-x" data-label={T.t('gen_details')}>
                                      {item}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          : null}
                      </td>
                      <td style={{ width: '13%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_date') + ' ' + T.t('gen_time')}>
                        {item.time}
                      </td>
                      <td style={{ width: '5%', verticalAlign: 'top' }} data-label={T.t('gen_actions')} className="table-buttons">
                        <div className="table-buttons-wrapper">
                          <button
                            id='button_edit'
                            data-toggle="tooltip"
                            // data-id={item.classroom_id}
                            // onClick={this.onClassroomEdit}
                            title={T.t('gen_edit')}
                            className="btn btn-light btn-sm table-button"
                          >
                            <span className="d-block" data-toggle="modal" data-target="#addNew">
                              <i className="material-icons">edit</i>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.statusHistoryList && this.state.statusHistoryList.length > 0 ? (
                <Paginate filters={this.state} onPageChange={this.onPageChange} />
              ) : null}
            </div>
          </div>
        </div>
      </div >
    );

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '95%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.onStopDistribution}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {this.props.term_type === 1 ? T.t('gen_exam_distribution') : T.t('gen_activity_distribution')}
            </h6>
            <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.onStopDistribution()}>
              <Timer startDate={this.state.created_date ? this.state.created_date : ''} />
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {CompatibilitiesAndButtons}
            <div className="white-container mt-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 courses">
                  {StatusBar}
                  {Table}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.changeSolutionModalIsOpen && (
          <ChangeSolutionModal
            modalIsOpen={this.state.changeSolutionModalIsOpen}
            onClose={this.switchChangeSolutionModalStatus}
          />
        )}
        {/* {this.state.solutionResultIsOpen && this.props.term_type === 1 ? (
          <ExamSolutionModal
            modalIsOpen={this.state.solutionResultIsOpen}
            onClose={this.switchSolutionResultsModalStatus}
            selectedPublicSolution={this.state}
          />
        ) : null} */}
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    results: store.state.distributor_page && store.state.distributor_page.results,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    distribution_in_progress_status: store.state.distribution_in_progress_status,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_page && prev.state.distributor_page.results,
        next.state.distributor_page && next.state.distributor_page.results
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      ) &&
      !!equal(
        prev.state && prev.state.distribution_in_progress_status,
        next.state && next.state.distribution_in_progress_status
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DistributorModal);

export default container;
