import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants } from '../constants/distributor-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';

const T = Translator.create();

function* getDistributors(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getDistributorStatusHistory(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSearchStatusHistory(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* cancelDistribution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionCancel(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_distribution_stopped', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}


function* getDistributorsInProgressStatus(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSearchInProgressStatus(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createDistribution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchDistributorSearch() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_DISTRIBUTION_LIST, getDistributors);
}

export function* watchDistributorStatusHistorySearch() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_STATUS_HISTORY, getDistributorStatusHistory);
}

export function* watchDistributorCancel() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CANCEL_DISTRIBUTION, cancelDistribution);
}

export function* watchDistributorSearchInProgressStatus() {
  yield takeEvery((action: any) => action.target === constants.GET_DISTRIBUTION_IN_PROGRESS_STATUS, getDistributorsInProgressStatus);
}

export function* watchDistributorCreate() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CREATE_DISTRIBUTION, createDistribution);
}