import * as Types from '../types';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
const L = Log.create('DashboardReducer');

function DashboardReducer(state: Types.IAppState, action: Types.IAction): Types.IAppState {
  let result: Types.IApiResponseAction;
  if (action.type === actions.dasboard.DASHBOARD_GET_SESSIONS) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, {
        dashboard_pages: {
          ...state.dashboard_pages,
          sessions: result.content ? result.content : []
        }
      });
    }
  }
  return state;
}

export default DashboardReducer;
