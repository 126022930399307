import { constants } from '../constants/distributor-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const distributorReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.DISTRIBUTOR_GET_DISTRIBUTION_LIST: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_page: {
            ...state.distributor_page,
            results: result.content ? result.content.data : [],
            filters: result.content ? result.content.filters : [],
          }
        };
      }
    }
    case constants.GET_DISTRIBUTION_IN_PROGRESS_STATUS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content != null) {
        return {
          ...state,
          distribution_in_progress_status: result.content ? result.content : {}
        };
      }
    }
    case constants.DISTRIBUTOR_GET_STATUS_HISTORY: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_status_history_page: {
            ...state.distributor_status_history_page,
            result: result.content ? result.content : []
          }
        };
      }
    }
    default: {
      return state;
    }
  }
};

export default distributorReducer;
