import React from 'react';
import * as Types from '../../store/types';
import { Log } from 'ng2-logger';
import Dropzone, {DropzoneRef} from 'react-dropzone';
import PerculusApiClient from '../../services/perculus-api-client';
import {Alert, Progress} from 'reactstrap';
import Translator from '../../services/translate-factory';
import * as Actions from '../../store/actions/general';
import * as GT from '../../tools/general-tools';

const L = Log.create('DropzoneUploader');
let _instance: DropzoneUploader;
declare var appSettings: Types.IAppSettings;
const T = Translator.create();

class DropzoneUploader extends React.Component<any, Types.IDropzoneState> {
    _timer: any;
    private _papi: PerculusApiClient = new PerculusApiClient();

    state: Types.IDropzoneState = {
        requests: [],
        uploads: [],
        rejected: [],
        dispatch: null
    };

    constructor(props: any) {
        super(props);
        if (_instance) {
            this.state = _instance.state;
        }
        _instance = this;
    }
    
    static getInstance = (): DropzoneUploader => {
        return _instance;
    }

    componentDidMount() {
        if (this.props.dispatch) {
            this.state.dispatch = this.props.dispatch;
        }
    }
    componentWillUnmount() {
        if (this._timer) {
            clearInterval(this._timer);
        }
    }

    onRequest = (action: Types.IFileUploadRequestAction) => {
        action.timestamp = Date.now();
        this.state.requests.push(action);
        this.setState({});
    };

    onDrop = (accepted: any, rejected: any, action: Types.IFileUploadRequestAction) => {
        L.data('onDrop', accepted, action);
        this.state.rejected = rejected;
        accepted = this.checkSpecificFileMaxSizes(accepted, action);
        this.state.uploads.push(...this.startUpload(accepted, action));
        if (this.state.rejected.length > 0) { 
            this.showInfoForRejecting(action);
        }
        this.setState(this.state);
    }

    onFileDialogCancel = (action: Types.IFileUploadRequestAction) => {
        L.data('onFileDialogCancel :', action);
        action.canceled = true;
        this.setState({});
        if (action.callback) {
            action.callback(null, 'canceled');
        }
    }

    startUpload(uploads: Array<any>, action: Types.IFileUploadRequestAction): Array<any> {
        L.data('startUpload:' + uploads);

        if (action && action.file)
        {
            var urlHost = appSettings.API_URL;

            uploads.forEach((file, i) => {
                if (file.hasOwnProperty('id')) {
                    return;
                }
                file.id = new Date().valueOf() + i;
            });
            
            uploads.forEach(file => {
                if (file.hasOwnProperty('uploader')) {
                    return;
                }

                file.callback = action.callback;

                var filename = file.name;

                var formData = new FormData();
                
                formData.append('file', file, filename);
                formData.append('dir_id', action.file ? action.file.dir_id : '');
                formData.append('session_id', action.file ? action.file.session_id: '');
                formData.append('name', filename);
                formData.append('description', '');
                formData.append('type', file.type);
                formData.append('size', file.size.toString());
                formData.append('directory', 'false');
                formData.append('shared', action.file && action.file.shared ? 'true' : 'false');
                formData.append('icon', 'icon-empty');
                formData.append('path', '');
                formData.append('container', action.file && action.file.container || '_docs');
                
                var uploader = new Uploader(file.id, urlHost + '/integration/upload-logo', this._papi._accessToken);
                uploader.onLoad = this.onLoad.bind(this);
                uploader.onProgress = this.onProgress.bind(this);
                uploader.onAbort = this.onAbort.bind(this);
                file.uploader = uploader;
                file.uploader.start(formData);
            });
            return uploads;
        } else {
            return [];
        }
    }

    onProgress(id: any, xhr: XMLHttpRequest, e: ProgressEvent) {
        L.data('onProgress id:' + id, e);
        
        if (e && e.loaded >= 0 && e.total >= 0) {
            var percent = ((e.loaded / e.total) * 100).toFixed(1);
            this.state.uploads.forEach((file: any) => {
                if (file.id === id) {
                    file.progress = percent;
                }
            });
            
            var progress = document.getElementsByClassName('progress-' + id);

            if (progress && progress.length > 0) {
                var progressEl = progress[0] as HTMLDivElement;
                L.data('onProgress :', percent);
                progressEl.style.width = percent + '%';
            }
        }
    
    }

    onLoad(id: any, xhr: XMLHttpRequest, e: ProgressEvent) {
        L.data('onLoad id:' + id, e);
        var index = this.state.uploads.findIndex((f) => f.id === id);
        const file: Types.IFileView = JSON.parse(xhr.response)

        if (xhr.status === 200) {
            if (index > -1 && this.state.uploads[index].callback) {
                this.state.uploads[index].callback(file, 'uploaded');
            }

            if (this.state.dispatch) {
                this.state.dispatch(Actions.UploadResponse(file, 'uploaded'));
            }
        }
        else{
            if (index > -1){
                this.state.uploads[index].callback(file, 'failed');
            }
        }
        
        if (index > -1) {
            this.state.uploads.splice(index, 1);
            var pconEl = document.getElementById('progress-' + id);
            if (pconEl) {
                pconEl.style.display = 'none';
            }
        }
    }

    checkPendingFiles = () => {
        /*
        if (this.state.files.filter(f => f.file_status === 'pending').length > 0) {
            setTimeout(() => this.getSessionFiles(), 10000);
        }
        */
    }

    onAbort(id: any) {
        L.data('onAbort id:' + id);
        
        let pconEl = document.getElementById('progress-' + id);
        if (pconEl){
           pconEl.style.backgroundColor = 'red'; 
           setTimeout(() => {
                var index = this.state.uploads.findIndex((f: any) => f.id === id);
                if (index > -1) {
                    if (this.state.uploads[index].callback) {
                        this.state.uploads[index].callback(null, 'aborted');
                    }
                    this.state.uploads.splice(index, 1);
                    var pconEl = document.getElementById('progress-' + id);
                    if (pconEl) {
                        pconEl.style.display = 'none';
                    }
                }
            }, 1000);
        }    
    }

    abortUpload = (e: any) => {
        const id: number = parseInt(e.currentTarget.dataset.id, 10);
        L.data('abortUpload id:' + id);

        var index = this.state.uploads.findIndex((f: any) => f.id === id);
        if (index > -1 && this.state.uploads[index].uploader) {
            this.state.uploads[index].uploader.stop();
        }   
    }

    showInfoForRejecting = (action: Types.IFileUploadRequestAction) => {
        if (this.state.rejected.length > 0) {
            const rejectedFiles = this.state.rejected.map((f) => {
                return (
                    <button key={'rejected-file-' + f.name} className="file-name">
                        <i className="material-icons">attach_file</i> {f.name}
                        <span>{GT.fileSize(f.size, true)}</span>
                    </button>
                );
            });
            
            const showMSOfficeWarning = this.state.rejected.filter(f => {
                const ext = f.name && f.name.substr(f.name.lastIndexOf('.') + 1).split('?')[0].toLowerCase();
                return /(docx?)|(xlsx?)|(pptx?)/ig.test(ext);
            }).length > 0;

            const uploadMessageModalBody = (
                <React.Fragment>
                    <div className="modal-header border-0">
                        <h5 className="modal-title d-inline-flex align-items-center">{T.t('file_upload_notification')}</h5>
                    </div>
                    <div className="border-top modal-body pt-1">
                        <div className="info-box">
                            <p><span>{T.t('file_not_allowed_title')}</span></p>
                        </div>
                        <h5></h5>
                        {rejectedFiles}
                    
                        <div className="question-note border-top">
                            <span><strong>{T.t('gen_note')} :</strong></span>
                            <ul>
                                <li>
                                    <div className="media align-items-center mb-3">
                                        <span><strong>{T.t('file_allowed_types_label')}</strong></span>
                                    </div>
                                </li>
                                {
                                    action.options.fileMaxSizes && 
                                    <React.Fragment>
                                        <li>
                                            <div className="media align-items-center">
                                                <span><strong>{T.t('file_application_types')}:</strong></span>
                                                <div className="media-body">
                                                    {GT.fileSize(action.options.fileMaxSizes.application || action.options.maxSize, true)}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media align-items-center">
                                                <span><strong>{T.t('file_video_types')}:</strong></span>
                                                <div className="media-body">
                                                    {GT.fileSize(action.options.fileMaxSizes.video || action.options.maxSize, true)}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media align-items-center">
                                                <span><strong>{T.t('file_audio_types')}:</strong></span>
                                                <div className="media-body">
                                                    {GT.fileSize(action.options.fileMaxSizes.audio || action.options.maxSize, true)}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media align-items-center">
                                                <span><strong>{T.t('file_image_types')}:</strong></span>
                                                <div className="media-body">
                                                    {GT.fileSize(action.options.fileMaxSizes.image || action.options.maxSize, true)}
                                                </div>
                                            </div>
                                        </li>
                                    </React.Fragment>
                                }
                            </ul>
                            <div 
                                style={{display: showMSOfficeWarning ? '' : 'none', maxWidth: '100%'}}
                                className="alert alert-info mb-3"
                                dangerouslySetInnerHTML={{ __html: T.t('file_ms_office_pdf_warning')}} 
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
            
            if (this.state.dispatch) {
                this.state.dispatch(Actions.ShowModal({
                    className: 'question-modal',            
                    content: uploadMessageModalBody,
                    name: 'upload-messages',
                    disableOwnContent: true
                }));
            }
        }
    }

    checkSpecificFileMaxSizes = (accepted: Array<any>, action: Types.IFileUploadRequestAction): Array<any> => {
        let names: Array<string> = [];
        const fileMaxSizes: any = action.options && action.options.fileMaxSizes ? action.options.fileMaxSizes : {};
        if (accepted && accepted.length > 0 && fileMaxSizes) {
            accepted.forEach((f: any) => {
                Object.keys(fileMaxSizes).forEach(key => {
                    if (f.type && f.size && f.type.startsWith(key) && fileMaxSizes[key]) {
                        if (f.size > fileMaxSizes[key]) {
                            names.push(f.name);
                        }
                    }
                });
            });
        }
        if (names.length > 0) {
            names.forEach(name => {
                const removed = accepted.splice(accepted.findIndex(f => f.name === name), 1);
                if (removed && removed.length > 0) {
                    this.state.rejected.push(removed[0]);
                }
            });
        }
        return accepted;
    }

    render() {
        const containers = this.state.requests.filter(a => !a.canceled && !a.completed ).map(action => {

            let onDrop = (accepted: any, rejected: any) => this.onDrop(accepted, rejected, action);
            let onFileDialogCancel = () => this.onFileDialogCancel(action);
            let onLoad = (dz: DropzoneRef) => {
                if (dz && !action.dropzone) {
                    action.dropzone = dz;
                    dz.open();
                }
            };

            return (
                <Dropzone
                    key={'a-' + action.timestamp}
                    ref={onLoad}
                    onDrop={onDrop}
                    noDrag={true}
                    noClick={true}
                    disabled={false}
                    noKeyboard={true}
                    maxSize={action.options.maxSize || 5024288000}
                    accept={action.options.accept || 'image/*'}
                    onFileDialogCancel={onFileDialogCancel}>
                    {({getInputProps}) => (<section><input {... getInputProps ({disabled: false})} /></section>)}
                </Dropzone>
            );
        });
        return (
        <div className="upload-container">
            {containers}
            {
                this.state.uploads.map((file: any) => {
                    return (
                        <Alert key={'progress-' + file.id} id={'progress-' + file.id} color={'sucess'} isOpen={true}>
                            <div className="media">
                                <div className="media-body">
                                    <span>{file.name}</span><br/>Yükleniyor
                                    <Progress barClassName={'progress-' + file.id} animated={true} color="warning" value={file.progress || 0}/>
                                </div>
                                <button type="button" className="close" data-id={file.id} aria-label="Close" onClick={this.abortUpload}>
                                        <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </Alert>
                    );
                })
            }
            
        </div>
        );
    }
}

export default DropzoneUploader;

export class Uploader {
    public _xhr: XMLHttpRequest;
    public onLoad: Function | undefined;
    public onProgress: Function | undefined;
    public onError: Function | undefined;
    public onAbort: Function | undefined;
    private _id: any;

    constructor(id: any, url: string, token: string) {
        this._id = id;
        var self = this;

        this._xhr = new XMLHttpRequest();
        this._xhr.open('POST', url, true);
        this._xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        this._xhr.upload.addEventListener(
            'progress', 
            function (e: any) {
                if (self.onProgress) {
                    self.onProgress.call(this, self._id, self._xhr, e);
                }
            },
            false
        );

        this._xhr.onload = function (e: any) {
            if (self.onLoad) {
                self.onLoad.call(this, self._id, self._xhr, e);
            }
        };

        this._xhr.onerror = function (e: any) {
            if (self.onError) {
                self.onError.call(this, self._id, self._xhr, e);
            }
        };

        this._xhr.onabort = function (e: any) {
            if (self.onAbort) {
                self.onAbort.call(this, self._id, self._xhr, e);
            }
        };
    }

    start(formData: FormData) {
        this._xhr.send(formData);
    }

    stop() {
        this._xhr.abort();
    }
}