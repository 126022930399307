import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SortedColumn from '../../../components/table/sorted-column';
import * as Types from '../../../store/types';

import * as Constants from '../../../store/constants/all';
import Translator from '../../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('ExamPeriodForm');

class CourseHoursListPageTableHeaders extends Component<any, any> {
  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  checker = (arr: Array<number>, target: Array<number>): boolean => {
    if (arr.length && target.length) {
      return target.every((item: number) => arr.includes(item));
    } else return false;
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.props.all_ids ? this.props.all_ids : [];
    const selected_ids = this.props.selected_ids ? this.props.selected_ids : [];
    let result: boolean = false;
    const selected_course_ids = this.props.selected_course_ids ? this.props.selected_course_ids : [];
    if (all_ids.length && selected_ids.length && selected_course_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  render() {
    let tableHeaders = null;

    let all_ids_on_list: Array<number> = [];
    if (this.props.courseList && this.props.courseList.length > 0) {
      this.props.courseList.map((item: Types.IExamPeriod_CourseItem) => {
        all_ids_on_list.push(item.course_id);
      });
    }

    if (this.props.compactTable) {
      tableHeaders = (
        <tr className="table-course-hours-classrooms-tr-headers">
          <th data-cell="select" className="table-course-hours-classrooms-th-1">
            <div className="tick-radio position-relative">
              <input
                type="checkbox"
                className="form-radio"
                checked={this.checkAllSelected()}
                // checked={this.checker(this.props.selected_ids ? this.props.selected_ids : [], all_ids_on_list)}
                onChange={this.props.fnSelectAll}
              />
            </div>
          </th>
          <SortedColumn
            datacell="status"
            title={T.t('gen_status_hour_assignment')}
            sortkey="status"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="status_classroom_assignment"
            title={T.t('gen_status_classroom_assignment')}
            sortkey="status_classroom_assignment"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="course_no"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-2"
            title={T.t('gen_course_no') + ' - ' + T.t('gen_code') + ' - ' + T.t('gen_name')}
            sortkey="course_no"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_duration"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1"
            title={T.t('gen_exam_duration') + ' - ' + T.t('gen_campus') + ' - ' + T.t('gen_total_student_count')}
            sortkey="exam_duration"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_supervisor"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1"
            title={T.t('gen_course_instructors')}
            sortkey="instructor"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="student_count"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1"
            title={T.t('gen_student_count') + ' - ' + T.t('gen_connected_programs') + ' - ' + T.t('gen_grade')}
            sortkey="student_count"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
        </tr>
      );
    } else {
      tableHeaders = (
        <tr>
          <th data-cell="select">
            <div className="tick-radio position-relative">
              <input
                type="checkbox"
                className="form-radio"
                checked={this.checkAllSelected()}
                // checked={this.checker(this.props.selected_ids ? this.props.selected_ids : [], all_ids_on_list)}
                onChange={this.props.fnSelectAll}
              />
            </div>
          </th>
          <SortedColumn
            datacell="status"
            title={T.t('gen_status')}
            sortkey="status"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <th scope="col" className="text-center">
            {T.t('gen_hours')}
          </th>
          <th scope="col" className="text-center">
            {T.t('gen_classrooms')}
          </th>
          <SortedColumn
            datacell="course_no"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_course_no')}
            sortkey="course_no"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="course_code"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_code')}
            sortkey="course_code"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="conjugate_course_code"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_conjugate_course_code')}
            sortkey="conjugate_course_code"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="name"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_name')}
            sortkey="name"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="student_count"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_student_count')}
            sortkey="student_count"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="total_student_count"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_total_student_count')}
            sortkey="total_student_count"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <th scope="col" className="text-center">
            <span className="text-center">{T.t('gen_grade')}</span>
          </th>
          <th scope="col" className="text-center">
            <span className="text-center">{T.t('gen_section')}</span>
          </th>
          <SortedColumn
            datacell="term"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_faculty')}
            sortkey="faculty_name"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="campus"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_campus')}
            sortkey="campus"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_duration"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_exam_duration')}
            sortkey="exam_duration"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_session"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_session_count')}
            sortkey="exam_session"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="classroom_combine_status"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_classroom_combine_status')}
            sortkey="classroom_combine_status"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_type"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_exam_type')}
            sortkey="exam_type"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_environment"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_exam_environment')}
            sortkey="exam_environment"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="exam_overlap_type"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_exam_overlap_type')}
            sortkey="exam_overlap_type"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="programs"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_connected_programs')}
            sortkey="programs"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="lecture_staff"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_lecture_staff')}
            sortkey="lecture_staff"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <th scope="col" className="text-center">
            {T.t('gen_assistants')}
          </th>
          <th scope="col" className="text-center">
            <span className="text-center">{T.t('gen_description')}</span>
          </th>
          <th scope="col" className="text-right">
            <span className="text-right">{T.t('gen_actions')}</span>
          </th>
        </tr>
      );
    }
    return <thead>{tableHeaders}</thead>;
  }
}

export default CourseHoursListPageTableHeaders;
