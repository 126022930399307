import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const BuildingFormValidation = (T: Translator) => Yup.object().shape({
  building_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  campus_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  loc_x: Yup.number().typeError(T.t('gen_enter_number')),
  loc_y: Yup.number().typeError(T.t('gen_enter_number')),
  description: Yup.string().max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
});
