import * as React from 'react';
import { useEffect, useState } from 'react';
import Translator from '../../services/translate-factory';

const T = Translator.create();

interface State {
  time: number;
  secounds: number;
  minutes: number;
  startDates: Date;
}
interface Props {
  startDate: string;
}
let createdDate: Date;

const Timer: React.FC<Props> = ({ startDate }) => {
  const [partyTime, setPartyTime] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const now1 = new Date();

  createdDate = new Date(now1.getTime());

  if (startDate != '' && startDate != undefined) {
    createdDate = new Date(startDate);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = (now.getTime() - createdDate.getTime());

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDays(d);

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(h);

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(m);

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(s);

      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setPartyTime(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{ fontSize: '1.3rem', marginRight: '8px' }}>{startDate != '' && startDate != undefined ? `${hours}: ${minutes}: ${seconds}` : T.t('gen_calculating_info')}</span>
  );
};

export default Timer;