import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import * as GT from '../../tools/general-tools';

const T = Translator.create();
const L = Log.create('SolutionEditModal');

let saveButtonClicked: boolean = false;

function getInitialState(): any {
  const initialValues: any = {

  };
  return Object.assign({}, initialValues);
}

class ChangeSolutionModal extends Component<any, any> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }


  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: Types.SolutionEditModalState) {
    let hasNewState: boolean = false;


    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onSolutionChange(values: any, FormActions: FormikActions<Types.IFilterSolutionCourseEditModal>) {
    if (saveButtonClicked) {

      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.props.dispatch(Actions.Notification(result.course_id + ' ' + T.t('notification_all_data_for_courses_changed'), 'gen_success'));
          this.setClose(true);
        }
      };

      let newModel = {
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_EDIT_COURSE, newModel, 'course-form-spin', resultCallback)
      );

      saveButtonClicked = false;
      FormActions.setSubmitting(false);
    }
  }

  render() {
    let listOptions: Array<Types.ISelectOption> = [
      { label: T.t('gen_date') + ' ' + T.t('gen_time'), value: 0 },
      { label: T.t('gen_max_exam_duration'), value: 1 },
      { label: T.t('gen_code'), value: 2 },
      { label: T.t('gen_name'), value: 3 },
      { label: T.t('gen_campus'), value: 4 },
      { label: T.t('gen_session_count'), value: 5 },
      { label: T.t('gen_total_student_count'), value: 6 },
      { label: T.t('gen_classroom') + ' - ' + T.t('gen_invigilator') + ' - ' + T.t('gen_number_students_classroom'), value: 7 },
      { label: T.t('gen_instructor') + ' - ' + T.t('gen_faculty') + ' - ' + T.t('gen_program') + ' - ' + T.t('gen_grade') + ' - ' + T.t('gen_section'), value: 8 }
    ];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_course_and_time_zone_distribution')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={this.state}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onSolutionChange(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterSolutionCourseEditModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;

                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              {/* <div className="react-select-container">
                                <label>{T.t('gen_editable_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_ids',
                                      list.map((item: any) => item.value)
                                    );
                                    if (list.map((item: any) => item.value).includes(listOptions[8].value)) {
                                      this.getSolutionCoursePrograms();
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }

  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    solutionCourseAddSelectOptions: store.state.select_options && store.state.select_options.solutionCoursePage,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    programs_id_related_faculty: store.state.select_options && store.state.select_options.programs_id_related_faculty,
    courseDetails: store.state && store.state.solution_page && store.state.solution_page.courseDetails,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeSolutionModal);

export default container;