import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import {
  CourseTermLectureLocationsForSolution,
  DayOfWeek,
  Weeks
} from '../../../store/constants/activity-const';
import { SolutionEditModalInitialValues } from '../../../store/constants/solution-const';
import MultiSelectionTable from '../components/multi-selection-table';
import Translator from '../../../services/translate-factory';
import { SolutionEditModalValidation } from './validations/solution-edit-modal-val';
import * as GT from '../../../tools/general-tools';

const T = Translator.create();
const L = Log.create('SolutionEditModal');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;
let combined_values_selected: Array<any> = new Array<any>();

function getInitialState(): Types.SolutionEditModalState {
  const initialValues: Types.SolutionEditModalState = {
    isAdded: false,
    alreadyInTheList: false,
    all_ids: [],
    selected_ids: [],
    week: ''
  };
  return Object.assign({}, initialValues);
}

class SolutionEditModal extends Component<any, Types.SolutionEditModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    let newModel = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId,
      activity_no: this.props.activityNo,
    };
    this.getActivitySelectOptions();
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_GET_ACTIVITY_PROGRAMS, newModel, 'solution-activity-programs-list-spin'));
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.spliceSubModalValues();
  }

  spliceSubModalValues() {
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  getProgramsByFacultiesAtActivities = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  getActivitySelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  getSolutionActivityPrograms() {
    if (combined_values_selected == undefined || combined_values_selected.length == 0) {
      this.putToTable(this.createFieldValues(), false);
    }
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.spliceSubModalValues();
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: Types.SolutionEditModalState) {
    let hasNewState: boolean = false;

    if (props && props.examDates) {
      hasNewState = true;
      const endHour = moment(props.examDates.end_hour, 'HH:mm').format('H');
      const max = parseInt(endHour, 10);
      state.minHour = props.examDates.start_hour;
      const maxHour = moment(max - Number(props.solutionEvent.lessonCount), 'H').format('HH:mm');
      state.maxHour = maxHour;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onSolutionEdit(values: any, FormActions: FormikActions<Types.IFilterSolutionEditModal>) {
    if (saveButtonClicked) {
      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.props.dispatch(Actions.Notification(result.activity_no + ' ' + T.t('notification_all_data_for_courses_changed'), 'gen_success'));
          this.setClose(true);
        }
      };

      let newModel = {
        term_id: this.props.term_id,
        solution_id: this.props.solutionId,
        activity_no: this.props.activityNo,
        activity_code: values.activity_code ? values.activity_code : this.props.solutionEvent.code,
        activity_name: values.activity_name ? values.activity_name : this.props.solutionEvent.name,
        week_of_day: values.week_of_day ? values.week_of_day : this.props.solutionEvent.dayOfWeek,
        lesson_count: values.lesson_count ? values.lesson_count : this.props.solutionEvent.lessonCount,
        start_hour: values.start_hour ? values.start_hour : this.props.solutionEvent.startHour,
        student_count: values.student_count ? values.student_count : this.props.solutionEvent.studentCount,
        campus_id: values.campuses ? values.campuses.value :
          this.props.solutionEvent.campus === CourseTermLectureLocationsForSolution(T)[0].label ? 1000 :
            this.props.solutionEvent.campus === CourseTermLectureLocationsForSolution(T)[1].label ? 1001 :
              this.props.solutionEvent.campus === CourseTermLectureLocationsForSolution(T)[2].label ? 1002 : 0,
        classroom_id: values.campuses ?
          ((values.campuses.value === 1000 || values.campuses.value === 1001 || values.campuses.value === 1002) ?
            null : (values.classrooms ? values.classrooms.value : 0))
          : values.classrooms ? values.classrooms.value : 0,
        instructor_ids: values.instructor_ids ? values.instructor_ids : [],
        program_ids: combined_values_selected.map((item: any) => item.program_id),
        faculty_ids: combined_values_selected.map((item: any) => item.faculty_id),
        sections: combined_values_selected.map((item: any) => item.section),
        weeks: values.weeks ? values.weeks.map((item: any) => item.value) : this.props.solutionEvent.weeks
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_EDIT_ACTIVITY, newModel, 'course-form-spin', resultCallback)
      );

      saveButtonClicked = false;
      FormActions.setSubmitting(false);
    }
  }

  disableEditButton = (values: Types.IFilterSolutionEditModal) => {
    let disableValue: boolean = false;
    if (values.selected_ids) {
      if (values.selected_ids.includes(0)) {
        if (!values.week_of_day || !values.start_hour || !values.lesson_count)
          disableValue = true;
      }
      if (values.selected_ids.includes(1)) {
        if (!values.activity_code || values.activity_code == "")
          disableValue = true;
      }
      if (values.selected_ids.includes(2)) {
        if (!values.activity_name || values.activity_name == "")
          disableValue = true;
      }
      if (values.selected_ids.includes(3)) {
        if (values.instructors && values.instructors.length == 0)
          disableValue = true;
      }
      if (values.selected_ids.includes(4)) {
        if (values.campuses && values.campuses.length == 0)
          disableValue = true;
      }
      if (values.selected_ids.includes(5)) {
        if (values.classrooms && values.classrooms.length == 0)
          disableValue = true;
      }
      if (values.selected_ids.includes(6)) {
        if (!values.student_count || values.student_count == "")
          disableValue = true;
      }
      if (values.selected_ids.includes(7)) {
        if (values.weeks && values.weeks.length == 0)
          disableValue = true;
      }
      return disableValue;
    }
    return true
  };

  refreshValuesClosedSelectedTypes = (values: Types.IFilterSolutionEditModal) => {
    if (values.selected_types.length == 0) {
      values.week_of_day = undefined;
      values.start_hour = undefined;
      values.lesson_count = undefined;
      values.activity_code = "";
      values.activity_name = "";
      values.instructors = [];
      values.campuses = [];
      values.classrooms = [];
      values.student_count = "";
      values.weeks = [];
      values.faculties = [];
      values.section = "";
      values.instructors = [];
      values.programs = [];
      this.spliceSubModalValues();
    }
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    values.section = values.section == '' ? '1' : values.section;

    combined_values_selected = values.faculty_ids && values.faculty_ids.map((element: any, index: number) => (
      {
        faculty_id: values.faculty_ids[index],
        faculty: values.faculties[index],
        program_id: values.program_ids[index],
        program: values.programs[index],
        section: values.sections[index],
        index: [...Array(values.faculty_ids && values.faculty_ids.length)].map((element: any, index: number) => index + 1)[index],
      }));

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    combined_values: any
  ) {
    multiSelectedRow = true;
    combined_values_selected = combined_values
  }

  createFieldValues = (): Types.IActivityItem => {
    let fields: Types.IActivityItem = {
      programs: [],
      program_ids: [],
      faculties: [],
      faculty_ids: [],
      sections: [],
    };
    let activityPrograms = this.props.activityDetails ? this.props.activityDetails : [];
    fields = {
      programs: activityPrograms ? activityPrograms.map((item: any) => ({ label: item.programName, value: item.programId })) : [],
      faculties: activityPrograms ? activityPrograms.map((item: any) => ({ label: item.facultyName, value: item.facultyId })) : [],
      program_ids: activityPrograms ? activityPrograms.map((item: any) => item.programId) : [],
      faculty_ids: activityPrograms ? activityPrograms.map((item: any) => item.facultyId) : [],
      sections: activityPrograms ? activityPrograms.map((item: any) => (item.section)) : [],
    };
    return fields;
  };

  render() {
    let listOptions: Array<Types.ISelectOption> = [
      { label: T.t('gen_date') + ' ' + T.t('gen_time'), value: 0 },
      { label: T.t('gen_code'), value: 1 },
      { label: T.t('gen_name'), value: 2 },
      { label: T.t('gen_instructor'), value: 3 },
      { label: T.t('gen_campus'), value: 4 },
      { label: T.t('gen_classroom'), value: 5 },
      { label: T.t('gen_student_count'), value: 6 },
      { label: T.t('gen_week'), value: 7 },
      { label: T.t('gen_faculty') + ' ' + T.t('gen_program') + ' ' + T.t('gen_section'), value: 8 }
    ];

    const campusOptions =
      this.props.solutionActivityPage && this.props.solutionActivityPage.campuses ? this.props.solutionActivityPage.campuses : [];
    const CampusSelectOptions = [...CourseTermLectureLocationsForSolution(T), ...campusOptions];

    let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.solutionEvent.weeks.length + 1);
    const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];
    const weekOptions = this.state.week.split(",").map((item: any) => ({ label: item, value: item }));
    let weekOptionSelector = this.state.week.split(",")[0] === "" ? true : false;

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_solution_edit')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={SolutionEditModalInitialValues}
                enableReinitialize={true}
                validationSchema={SolutionEditModalValidation}
                onSubmit={(values, actions) => {
                  this.onSolutionEdit(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterSolutionEditModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;

                  this.refreshValuesClosedSelectedTypes(values);
                  this.disableEditButton(values);

                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-warning" role="alert" style={{ maxWidth: 'none' }}>
                            {T.t('gen_solution_edit_warning')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_editable_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_ids',
                                      list.map((item: any) => item.value)
                                    );
                                    if (list.map((item: any) => item.value).includes(listOptions[8].value)) {
                                      this.getSolutionActivityPrograms();
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-4">
                              <div className="react-select-container">
                                <label>{T.t('gen_day')}</label>
                                <Select
                                  id='select_day'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={T.t_options(DayOfWeek, 'null')}
                                  placeholder={T.t('gen_select_day')}
                                  values={props.values.week_of_day}
                                  value={
                                    props.values.week_of_day != undefined && props.values.week_of_day != null
                                      ? T.t_options(DayOfWeek, "null").find((option) => option.value == props.values.week_of_day)
                                      : null
                                  }
                                  onChange={(option: any) => props.setFieldValue('week_of_day', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_day')}
                                />
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-4 form-input form-group with-icon">
                              <div className="add-custom-tag mt-3">
                                <Flatpickr
                                  id='start_hour'
                                  value={props.values.start_hour}
                                  placeholder={T.t('gen_select_time')}
                                  options={{
                                    enableTime: true,
                                    dateFormat: 'H:i',
                                    noCalendar: true,
                                    time_24hr: true,
                                    maxDate: this.state.maxHour ? this.state.maxHour : undefined,
                                    minDate: this.state.minHour ? this.state.minHour : undefined
                                  }}
                                  onClose={(value) => props.setFieldValue('start_hour', moment(value[0]).format('HH:mm'))}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="start-time-datepicker">{T.t('gen_start_time')}</label>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-4 form-input form-group with-icon mt-2">
                              <div className="add-custom-tag mt-2">
                                <input
                                  id="lesson_count"
                                  name="lesson_count"
                                  value={values.lesson_count}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  placeholder={T.t('gen_lesson_count')}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="lesson_count"><b>{T.t('gen_lesson_count')}</b></label>
                                {values && values.lesson_count && values.lesson_count < 1 && (
                                  <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                                )}
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[1].value) ?
                            <div className="col-md-4 form-input form-group with-icon mt-2 ml-3">
                              <div className="add-custom-tag mt-2">
                                <input
                                  id="activity_code"
                                  name="activity_code"
                                  value={values.activity_code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder={T.t('gen_code')}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="activity_code"><b>{T.t('gen_code')}</b></label>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[2].value) ?
                            <div className="col-md-4 form-input form-group with-icon mt-2 ml-3">
                              <div className="add-custom-tag mt-2">
                                <input
                                  id="activity_name"
                                  name="activity_name"
                                  value={values.activity_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder={T.t('gen_name')}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="name"><b>{T.t('gen_name')}</b></label>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[3].value) ?
                            <div className="col-md-12">
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_instructors')}</label>
                                    <Select
                                      id='select_instructor'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.solutionActivityPage && this.props.solutionActivityPage.instructors
                                          ? this.props.solutionActivityPage.instructors
                                          : []
                                      }
                                      placeholder={T.t('gen_select_instructor')}
                                      value={props.values.instructors ? props.values.instructors : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('instructors', list);
                                        props.setFieldValue(
                                          'instructor_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[4].value) ?
                            <div className="col-md-12">
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_campus')}</label>
                                    <Select
                                      id='select_location'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={CampusSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.campuses ? props.values.campuses : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('campuses', list);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[5].value) ?
                            <div className="col-md-12">
                              <div className="col-md-7">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classrooms')}</label>
                                    <Select
                                      id='select_classroom'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.solutionActivityPage && this.props.solutionActivityPage.classrooms
                                          ? this.props.solutionActivityPage.classrooms
                                          : []
                                      }
                                      placeholder={T.t('gen_select_classroom')}
                                      value={props.values.classrooms ? props.values.classrooms : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classrooms', list);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[6].value) ?
                            <div className="col-md-4 form-input form-group with-icon mt-2 ml-3">
                              <div className="add-custom-tag mt-2">
                                <input
                                  id="student_count"
                                  name="student_count"
                                  value={values.student_count}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  placeholder={T.t('gen_student_count')}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="student_count"><b>{T.t('gen_student_count')}</b></label>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[7].value) ?
                            <div className="col-md-12">
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_week')}</label>
                                    <Select
                                      id='select_week'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={WeekSelectOptions}
                                      placeholder={T.t('gen_select_week')}
                                      value={weekOptionSelector ? props.values.weeks : weekOptions}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('weeks',
                                          list.map(item => item.value).includes(-1) ? Weeks : list
                                        );
                                        props.setFieldValue(
                                          'week',
                                          list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                        );
                                        weekOptionSelector = true;
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[8].value) ?
                            <div className="col-12 text-md-center mb-3">
                              <MultiSelectionTable
                                combined_values={combined_values_selected}
                                multiSelectionObjectFunction={this.multiSelecteds}
                              />
                            </div> : null
                        }
                        {
                          props.values.selected_types.length !== 0 ?
                            <div className="col-12 pt-3 pb-3 pr-5">
                              <button
                                id='button_change'
                                type="button"
                                className="btn btn-green float-right"
                                disabled={this.disableEditButton(values)}
                                onClick={() => {
                                  props.handleSubmit()
                                  saveButtonClicked = true
                                }}
                              >
                                {T.t('gen_change')}
                              </button>
                            </div> : null
                        }
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    term_id: store.state.term_id,
    solutionActivityPage: store.state.select_options && store.state.select_options.solutionActivityPage,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates,
    activityDetails: store.state && store.state.solution_page && store.state.solution_page.activityDetails,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionEditModal);

export default container;