import { escapeLeadingUnderscores } from 'typescript';
import * as Types from '../types';
import Translator from '../../services/translate-factory';

export const constants = {
  DISTRIBUTOR_GET_DISTRIBUTION_LIST: 'DISTRIBUTOR:GET_DISTRIBUTION_LIST',
  DISTRIBUTOR_GET_STATUS_HISTORY: 'DISTRIBUTOR:GET_STATUS_HISTORY',
  DISTRIBUTOR_CREATE_DISTRIBUTION: 'DISTRIBUTOR:CREATE_DISTRIBUTION',
  DISTRIBUTOR_CANCEL_DISTRIBUTION: 'DISTRIBUTOR:CANCEL_DISTRIBUTION',
  GET_DISTRIBUTION_IN_PROGRESS_STATUS: 'DISTRIBUTOR:GET_DISTRIBUTION_IN_PROGRESS_STATUS',
};

export const StatusSelectOptions = (T: Translator) => [
  { label: T.t('gen_success'), value: 9 },
  { label: T.t('gen_failed'), value: -2 },
  { label: T.t('gen_in_progress'), value: 1 }
];

export const DistributorModalSearchInitialValues: Types.IFilterDistributorModal = {
  order_by: '',
  page: 1,
  total: -1,
  size: 10,
};

export const DistributorSearchInitialValues: Types.IFilterDistributor = {
  order_by: '',
  page: 1,
  total: -1,
  size: 10,
};

export const DistributorJobStatus: Types.IDistributorJobStatus = {
  Stopped: -2,
  UnexpectedError: -1,

  // Create dll XMLs
  DataPreparation_Pending: 0,
  DataPreparation_Ready: 1,
  DataPreparation_Error: 2,

  // Check data
  ImpossibilityTest_Pending: 3,
  ImpossibilityTest_Ready: 4,
  ImpossibilityTest_Error: 5,

  // Calculations
  Calculations_Pending: 6,
  Calculations_Ready: 7,
  Calculations_Error: 8,

  Completed_Successfully: 9,
  // TODO: Calculations status acc. dll
  // ...
}