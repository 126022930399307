import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { DistributorModalSearchInitialValues } from '../../store/constants/distributor-const';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('DistributorStatusHistoryModal');

class DistributorStatusHistoryModal extends Component<any, any> {
  state: Types.IDistributorModalState = {
    filters: DistributorModalSearchInitialValues,
    statusTableIsOpen: false,
    filterIsOpen: false,
    statusHistoryList: [],
    changeSolutionModalIsOpen: false,
    solutionResultIsOpen: false,
    page: 1,
    total: -1,
    size: 10,
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.getStatusHistoryDistributions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getStatusHistoryDistributions() {
    this.state.filters.job_id = this.props.jobId;
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_STATUS_HISTORY, this.state.filters, 'ep-student-list')
    );
  }

  showInfoModalStatusHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_all_info'),
          body: T.t('gen_download_all_question'),
          name: 'download_status_history',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.term_id,
              sectionType: 32,
              sectionId: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              parameters: []
            };
            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  onFilterDistributionList(model: Types.IFilterDistributorModal, FormActions: FormikActions<Types.IFilterDistributorModal>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getStatusHistoryDistributions();
    FormActions.setSubmitting(false);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getStatusHistoryDistributions();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getStatusHistoryDistributions();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorModalSearchInitialValues);
    this.setState(this.state);
    this.getStatusHistoryDistributions();
  };

  static getDerivedStateFromProps(props: Types.IDistributorPageProps, state: Types.IDistributorPageState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let statusHistoryList = this.props && this.props.result && this.props.result.status_history_list

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '95%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_status_history')}
            </h6>
            <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseModal}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 courses">
                <div className="col-12">
                  <div className="quick-actions">
                    <div className="row">
                      <div className="col-12 mt-1">
                        <button
                          id='button_list_sync'
                          className="category-tag-square tag-blue float-left ml-3 mr-3"
                          onClick={this.showInfoModalStatusHistory}>
                          <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                          <span>{T.t('gen_download')}</span>
                        </button>
                        <button
                          id='button_filter'
                          className="category-tag-square tag-glass float-right ml-3 mr-3"
                          onClick={() => {
                            this.state.filterIsOpen = !this.state.filterIsOpen;
                            this.setState(this.state);
                          }}
                        >
                          <i className="material-icons mr-2">filter_list</i>
                          <span>{T.t('gen_filter')}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div >
                <div className={`white-container mt-1 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={DistributorModalSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        values.term_id = this.state.filters.term_id;
                        this.onFilterDistributionList(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterStudent>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {T.t('gen_number')}
                        </th>
                        <SortedColumn
                          datacell="message"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title={T.t('gen_message')}
                          sortkey="message"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="time"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title={T.t('gen_date') + ' ' + T.t('gen_time')}
                          sortkey="time"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_actions')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {statusHistoryList && statusHistoryList.length ? (
                        statusHistoryList.map((item: any) => {
                          let statusHistoryId = item.id ? item.id : -1;
                          let formattedErrorMessages = item.message.formattedErrorMessage ? item.message.formattedErrorMessage : []
                          return (
                            <tr key={'status-history-' + statusHistoryId} data-title={item.distribution_number}>
                              <td data-label={T.t('gen_number')}>
                                {item.id}
                              </td>
                              <td data-label={T.t('gen_message')}>
                                {item.message.errorMessage}
                                {formattedErrorMessages && formattedErrorMessages.length ?
                                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          {T.t('gen_number')}
                                        </th>
                                        <th scope="col">
                                          {T.t('gen_details')}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formattedErrorMessages.map((item: any, index: any) => {
                                        return (
                                          <tr key={'status-history-detail-' + index}>
                                            <td data-label={T.t('gen_number')}>
                                              {index}
                                            </td>
                                            <td data-label={T.t('gen_details')}>
                                              {item}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                  : null}
                              </td>
                              <td data-label={T.t('gen_date') + ' ' + T.t('gen_time')}>
                                {item.time}
                              </td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                <div className="table-buttons-wrapper">
                                  <button
                                    id='button_edit'
                                    data-toggle="tooltip"
                                    // data-id={item.classroom_id}
                                    // onClick={this.onClassroomEdit}
                                    title={T.t('gen_edit')}
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={24}>
                            <Announce title={T.t('gen_no_records_found')} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    result: store.state.distributor_status_history_page && store.state.distributor_status_history_page.result,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_status_history_page && prev.state.distributor_status_history_page.result,
        next.state.distributor_status_history_page && next.state.distributor_status_history_page.result
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DistributorStatusHistoryModal);

export default container;
