import { actions } from '../constants/setting-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

import { Log } from 'ng2-logger';

const Logger = Log.create('settingsReducer');

const settingsReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case actions.GET_SETTINGS: {
      const response: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        settings: response.content ? response.content : {}
      });
      return state;
    }
    case actions.GET_GENERAL_SETTINGS: {
      const response: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        settings: response.content ? response.content : {}
      });
      return state;
    }
    default: {
      return state;
    }
  }
};

export default settingsReducer;
