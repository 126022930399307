import * as Types from '../types';

import Translator from '../../services/translate-factory';

export const actions = {
  SETTING_RESET_PASSWORD: 'SETTING:RESET_PASSWORD',
  GET_SETTINGS: 'SETTING:GET_SETTINGS',
  GET_GENERAL_SETTINGS: 'SETTING:GET_GENERAL_SETTINGS',
  UPDATE_SETTINGS: 'SETTING:SET_SETTINGS'
};

export const EmailServiceSelectOptions = (T: Translator) => [
  { label: 'Gmail', value: 'Gmail' },
  { label: 'Mandrill', value: 'Mandrill' },
  { label: 'Outlook', value: 'Outlook' },
  { label: 'Yandex', value: 'Yandex' },
  { label: T.t('gen_other'), value: 'other' }
];

export const ClassroomInvigilatorCountSettings = (T: Translator) => [
  { label: T.t('settings_classroom_invigilator_count_settings_opt1'), value: 0 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt2'), value: 1 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt3'), value: 2 }
];

export const InvigilatorAssignTypes = (T: Translator) => [
  { label: T.t('settings_invigilator_assign_type_opt1'), value: 0 },
  { label: T.t('settings_invigilator_assign_type_opt2'), value: 1 }
];

export const ResetPasswordFormInitialValues: Types.IResetPassword = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
};

export const SettingsPageRoutes = {
  ChangePassword: '/password'
};
