import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import { Formik, FormikProps, FormikActions } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Announcement from '../../assets_aplan/images/announcement.svg';
import Paginate from '../../components/table/paginate';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CalendarModalSearchInitialValues,
  InstructorHoursDataModelValues,
  InstructorHoursInitialValues,
  SelectedInstructorEmptyValues
} from '../../store/constants/exam-period-const';
import {
  Days
} from '../../store/constants/course-period-const';
import * as Types from '../../store/types';
import InstructorAddModal from './instructor-hours-add-modal';
import { StaffTypes, Titles, DailyMaxClasses } from '../../store/constants/instructor-const';
import { UncontrolledTooltip } from 'reactstrap';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('ExamDates');

function getInitialState(): Types.IExamPeriod_Instructor_TabState {
  const initialValues: Types.IExamPeriod_Instructor_TabState = {
    model: Object.assign({}, InstructorHoursInitialValues),
    dataModel: Object.assign({}, InstructorHoursDataModelValues),
    filters: Object.assign({}, CalendarModalSearchInitialValues)
  };
  return Object.assign({}, initialValues);
}

class Schedule extends Component<Types.IExamPeriod_InstructorProps, Types.IExamPeriod_Instructor_TabState> {
  state: Types.IExamPeriod_Instructor_TabState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.selectActiveTab(0);
    this.getProgramsByFacultiesAtInstructors(this.state.model.invigilations.invigilation_faculty_ids);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    if (
      !this.state.model.is_calendar_created &&
      this.state.model.calendar.days.length &&
      this.state.model.calendar.times.length &&
      Object.keys(this.state.model.calendar.schedule).length
    ) {
      this.createModifiedSchedule();
    }
    if (
      this.state.model.is_calendar_created &&
      this.props.user &&
      this.props.user.role === 'e' &&
      this.state.model.instructor_list.length &&
      (this.state.model.controlledUpdate >= 1 || this.props.tabReloaded())
    ) {
      this.onSelectInstructor(undefined, 'instructorOnly');
      this.state.model.controlledUpdate = 0;
    }
  }

  getInstructorHours() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS,
        this.state.filters,
        'ep-instructor-list'
      )
    );
  }

  getProgramsByFacultiesAtInstructors = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'instructor-list-spin'));
  }

  closeAddModal = () => {
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        addModalIsOpen: !this.state.model.addModalIsOpen
      }
    });
  };

  createModifiedSchedule() {
    let scheduleClone: any = Object.assign({}, this.state.model.calendar.schedule);
    let daysClone: any = Object.assign([], this.state.model.calendar.days);
    let resultSchedule: any = {};

    daysClone.map((day: any) => {
      let newArray: any = [];
      scheduleClone[day].map((item: any) => {
        let obj = {
          hour: item.hour,
          status: item.isActive == true ? 'empty' : 'disabled'
        };
        newArray.push(obj);
      });
      resultSchedule[day] = newArray;
    });

    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        calendar: {
          ...this.state.model.calendar,
          schedule: JSON.parse(JSON.stringify(resultSchedule))
        },
        initialSchedule: JSON.parse(JSON.stringify(resultSchedule)),
        is_calendar_created: true
      }
    });
  }

  convertDataToSchedule() {
    let data_time_slots: any =
      this.state.model.selected_Instructor && this.state.model.selected_Instructor.time_slots
        ? this.state.model.selected_Instructor.time_slots
        : {};

    let data_invigilator_slots: any =
      this.state.model.selected_Instructor && this.state.model.selected_Instructor.invigilator_time_slots
        ? this.state.model.selected_Instructor.invigilator_time_slots
        : {};

    let timeSlotsDays = Object.keys(data_time_slots);
    let invigilatorSlotsDays = Object.keys(data_invigilator_slots);
    let dateObject_timeSlots: any = {};
    let dateObject_invigilatorSlots: any = {};
    let result_timeSlots: any = {};
    let result_invigilatorSlots: any = {};
    let result_commonSlots: any = {};
    let scheduleClone: any = this.state.model.calendar.schedule;

    // Seçilen öğretim görevlisinin kendi saatleri ve gözetmenlik saatleri var mı diye kontrol eder.
    // Sadece biri varsa onu alır ve state' e koyar. Eğer her ikisi de varsa, önce birini kullanarak
    // takvim klonu oluşturur ve diğerini oluşturduğu bu klonun üzerine yazar.

    if (timeSlotsDays && !timeSlotsDays.length && invigilatorSlotsDays && !invigilatorSlotsDays.length) {
      let initialCalendar = this.state.model.initialSchedule
        ? JSON.parse(JSON.stringify(this.state.model.initialSchedule))
        : {};

      if (this.state.model && this.state.model.calendar && this.state.model.calendar.schedule) {
        this.state.model.calendar.schedule = initialCalendar;
        this.setState(this.state);
      }
    } else if (timeSlotsDays && timeSlotsDays.length && invigilatorSlotsDays && !invigilatorSlotsDays.length) {
      timeSlotsDays.map((day: string) => {
        dateObject_timeSlots = scheduleClone[day];
        data_time_slots[day].map((dataHour: string) => {
          dateObject_timeSlots = dateObject_timeSlots.map((item: { hour: string; status: any }) =>
            item.hour == dataHour ? { hour: item.hour, status: 'own_exam' } : item
          );
        });
        result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
          [day]: dateObject_timeSlots
        });
      });
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          calendar: {
            ...this.state.model.calendar,
            schedule: result_timeSlots
          }
        }
      });
    } else if (invigilatorSlotsDays && invigilatorSlotsDays.length && timeSlotsDays && !timeSlotsDays.length) {
      invigilatorSlotsDays.map((day: string) => {
        dateObject_invigilatorSlots = scheduleClone[day];
        data_invigilator_slots[day].map((dataHour: string) => {
          dateObject_invigilatorSlots = dateObject_invigilatorSlots.map((item: { hour: string; status: any }) =>
            item.hour == dataHour ? { hour: item.hour, status: 'invigilator' } : item
          );
        });
        result_invigilatorSlots = Object.assign(scheduleClone, result_invigilatorSlots, {
          [day]: dateObject_invigilatorSlots
        });
        this.setState({
          ...this.state,
          model: {
            ...this.state.model,
            calendar: {
              ...this.state.model.calendar,
              schedule: result_invigilatorSlots
            }
          }
        });
      });
    } else if (invigilatorSlotsDays && invigilatorSlotsDays.length && timeSlotsDays && timeSlotsDays.length) {
      let clone_1 = JSON.parse(JSON.stringify(data_time_slots));
      let commonHoursObject: any = {};

      timeSlotsDays.map((key: string) => {
        let newArray: any = [];
        if (invigilatorSlotsDays.includes(key)) {
          clone_1[key].map((hour: string) => {
            if (data_invigilator_slots[key].includes(hour)) {
              let hourItem = clone_1[key].find((item: string) => item == hour);
              newArray.push(hourItem);
            }
          });
        }
        if (newArray.length) {
          commonHoursObject[key] = newArray;
        }
      });

      invigilatorSlotsDays.map((day: string) => {
        dateObject_invigilatorSlots = scheduleClone[day];
        data_invigilator_slots[day].map((dataHour: string) => {
          dateObject_invigilatorSlots = dateObject_invigilatorSlots.map((item: { hour: string; status: any }) =>
            item.hour == dataHour ? { hour: item.hour, status: 'invigilator' } : item
          );
        });
        result_invigilatorSlots = Object.assign(scheduleClone, result_invigilatorSlots, {
          [day]: dateObject_invigilatorSlots
        });
      });
      if (Object.keys(result_invigilatorSlots).length) {
        timeSlotsDays.map((day: string) => {
          dateObject_timeSlots = scheduleClone[day];
          data_time_slots[day].map((dataHour: string) => {
            dateObject_timeSlots = dateObject_timeSlots.map((item: { hour: string; status: any }) =>
              item.hour == dataHour ? { hour: item.hour, status: 'own_exam' } : item
            );
          });
          result_timeSlots = Object.assign(result_invigilatorSlots, result_timeSlots, {
            [day]: dateObject_timeSlots
          });
        });
      }
      if (Object.keys(commonHoursObject).length) {
        let commonDays = Object.keys(commonHoursObject);
        let dateObject_commonHours: any = {};
        let timeSlots_helperObject = JSON.parse(JSON.stringify(result_timeSlots));
        commonDays.map((day: string) => {
          dateObject_commonHours = timeSlots_helperObject[day];
          commonHoursObject[day].map((dataHour: string) => {
            dateObject_commonHours = dateObject_commonHours.map((item: { hour: string; status: any }) =>
              item.hour == dataHour ? { hour: item.hour, status: 'both' } : item
            );
          });
          result_commonSlots = Object.assign(result_timeSlots, result_commonSlots, {
            [day]: dateObject_commonHours
          });
        });
      }
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          calendar: {
            ...this.state.model.calendar,
            schedule: Object.keys(result_commonSlots).length > 0 ? result_commonSlots : result_timeSlots
          }
        }
      });
    }
    this.forceUpdate();
  }

  saveInstructorHours(mailStatus: boolean, addType: string) {
    let dataModel: Types.IExamPeriod_InstructorDataModel = Object.assign({}, InstructorHoursDataModelValues);
    let scheduleClone: any = this.state.model.calendar.schedule;
    let timeSlotsArray: any = [];
    let invigilatorSlotsArray: any = [];
    let timesHelperObject: any = {};
    let invigilatorHelperObject: any = {};
    let result_timeSlots: any = {};
    let result_invigilatorSlots: any = {};

    this.state &&
      this.state.model.calendar.days.map((day: string) => {
        scheduleClone[day].map((item: { hour: string; status: string }) => {
          if (item.status == 'own_exam' || item.status == 'both') {
            timeSlotsArray.push(item.hour);
          }
          if (item.status == 'invigilator' || item.status == 'both') {
            invigilatorSlotsArray.push(item.hour);
          }
        });
        result_timeSlots = Object.assign(timesHelperObject, timeSlotsArray.length ? { [day]: timeSlotsArray } : {});

        result_invigilatorSlots = Object.assign(
          invigilatorHelperObject,
          invigilatorSlotsArray.length ? { [day]: invigilatorSlotsArray } : {}
        );

        timeSlotsArray = [];
        invigilatorSlotsArray = [];
      });
    if (addType == 'single') {
      dataModel = {
        term_id: this.state.model.term_id,
        instructor_id: this.state.model.selected_Instructor ? this.state.model.selected_Instructor.instructor_id : -1,
        send_mail: mailStatus,
        invigilator_time_slots: result_invigilatorSlots,
        time_slots: result_timeSlots
      };
    } else if (addType == 'multiple') {
      dataModel = {
        term_id: this.state.model.term_id,
        instructor_hour_ids: this.state.model.checked_list,
        send_mail: mailStatus,
        invigilator_time_slots: result_invigilatorSlots,
        time_slots: result_timeSlots
      };
    }

    this.props.dispatch(
      Actions.ShowModal({
        title: `${mailStatus ? T.t('gen_mail_will_sent_instructors') : T.t('gen_will_recorded_results')}`,
        body: T.t('gen_instructor_hour_save_question').replace('{0}', mailStatus ? T.t('gen_mail_will_sent_instructors_info') : T.t('gen_will_recorded_results_info')),
        name: 'instructorHours_save',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_save'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (this.props.user && this.props.user.role === 'e' && status == 200) {
              this.getInstructorHours();
            }
            if (status == 200 && this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e')) {
              this.state.model.selected_Instructor = SelectedInstructorEmptyValues;
              this.state.model.checked_list = [];
              this.setState(this.state);
              this.getInstructorHours();
            }
          };
          if (addType == 'single') {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_UPDATE_INSTRUCTOR_HOURS,
                dataModel,
                'exam-period-modal-tab-spin',
                resultCallback
              )
            );
            this.selectActiveTab(3)
          } else if (addType == 'multiple') {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_UPDATE_MULTIPLE_INSTRUCTOR_HOURS,
                dataModel,
                'exam-period-modal-tab-spin',
                resultCallback
              )
            );
            this.selectActiveTab(3)
          }
        }
      })
    );
  }

  createBadge(degree: number) {
    switch (degree) {
      case 0:
        return (
          <span className="badge badge-danger" style={{ fontSize: '75%', textAlign: 'center' }}>
            Gözetmenlik Yapmaz
          </span>
        );
      case 1:
        return (
          <span className="badge badge-warning" style={{ fontSize: '75%' }}>
            Çok Düşük
          </span>
        );
      case 2:
        return (
          <span className="badge badge-warning" style={{ fontSize: '75%' }}>
            Düşük
          </span>
        );
      case 3:
        return (
          <span className="badge badge-info" style={{ fontSize: '75%' }}>
            Orta
          </span>
        );
      case 4:
        return (
          <span className="badge badge-success" style={{ fontSize: '75%' }}>
            Yüksek
          </span>
        );
      case 5:
        return (
          <span className="badge badge-success" style={{ fontSize: '75%' }}>
            Çok Yüksek
          </span>
        );
      default:
        return (
          <span className="badge badge-dark" style={{ fontSize: '75%' }}>
            Derece Verilmedi
          </span>
        );
    }
  }

  chooseHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const hour: string = e.currentTarget.dataset.hour || '';
      const day: string = e.currentTarget.dataset.day || '';
      let newSchedule = scheduleClone[day].map((item: any) =>
        item.hour == hour && item.status != 'disabled' && this.adminSelectedHour(day, item.hour)
          ? {
            ...item,
            status: item.status == this.state.model.addType ? 'empty' : this.state.model.addType
          }
          : item
      );
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllHoursOfDay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const day: string = e.currentTarget.dataset.day || '';

      let checkArray = scheduleClone[day].map((item: any) => item.status);

      let otherAddTypes = ['own_exam', 'invigilator', 'both'].filter(
        (item: string) => item != this.state.model.addType
      );

      let allEmptySlotsAreSelected: boolean =
        checkArray.includes(this.state.model.addType) &&
        !checkArray.includes(otherAddTypes[0]) &&
        !checkArray.includes(otherAddTypes[1]) &&
        checkArray.indexOf('empty') == -1;

      let newSchedule = scheduleClone[day].map((item: any) => {
        if (item.status != 'disabled' && this.adminSelectedHour(day, item.hour)) {
          if (allEmptySlotsAreSelected) {
            return { ...item, status: 'empty' };
          } else {
            return { ...item, status: this.state.model.addType };
          }
        } else return item;
      });

      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.state.model.all_ids ? this.state.model.all_ids : [];
    const selected_ids = this.state.model.checked_list ? this.state.model.checked_list : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  deleteInstructors = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_instructors_remove_planning'),
          body: T.t('gen_remove_instructors_from_course_schedule_question'),
          name: 'examPeriod_instructors_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Response, status: number) => {
              if (status == 200) {
                this.state.model.checked_list = [];
                this.state.filters.delete_list = [];
                this.state.model.selected_Instructor = SelectedInstructorEmptyValues;
                this.setState(this.state);
                this.getInstructorHours();
              }
            };

            this.state.filters.delete_list = this.state.model.checked_list;
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_INSTRUCTORS_FROM_PERIOD,
                this.state.filters,
                'ep-instructor-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getInstructorHours();
  };

  chooseAllDaysofHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let newSchedule = {};
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      const time: string = e.currentTarget.dataset.time || '';

      let otherAddTypes = ['own_exam', 'invigilator', 'both'].filter(
        (item: string) => item != this.state.model.addType
      );

      let checkArray: any = [];
      daysClone.map((day: string) =>
        scheduleClone[day].map((item: any) => (item.hour == time ? checkArray.push(item.status) : null))
      );

      let allEmptySlotsAreSelected: boolean =
        checkArray.includes(this.state.model.addType) &&
        !checkArray.includes(otherAddTypes[0]) &&
        !checkArray.includes(otherAddTypes[1]) &&
        checkArray.indexOf('empty') == -1;

      daysClone.map((day: string) => {
        let dayObject = scheduleClone[day].map((item: any) => {
          if (item.hour == time) {
            if (allEmptySlotsAreSelected) {
              return item.status != 'disabled' && this.adminSelectedHour(day, item.hour)
                ? { ...item, status: 'empty' }
                : item;
            } else {
              return item.status != 'disabled' && this.adminSelectedHour(day, item.hour)
                ? { ...item, status: this.state.model.addType }
                : item;
            }
          } else return item;
        });
        newSchedule = Object.assign(newSchedule, { [day]: dayObject });
      });
      let schedule = newSchedule;
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, schedule);
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  clearAllHours = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_tablo_clear_info'),
        body: T.t('gen_clear_hours_warning'),
        name: 'examPeriod_delete',
        icon: 'error_outline',
        iconColor: 'red',
        confirm: T.t('gen_clear'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          if (e && e.currentTarget) {
            let newSchedule = {};
            let scheduleClone: any = this.state.model.calendar.schedule;
            let daysClone: any = this.state.model.calendar.days;
            daysClone.map((day: string) => {
              let dayObject = scheduleClone[day].map((item: any) => Object.assign({}, item, { isActive: false }));
              newSchedule = Object.assign(newSchedule, { [day]: dayObject });
            });
            let schedule = newSchedule;
            let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, schedule);
            this.state.model.calendar.schedule = updatedSchedule;
            this.setState(this.state);
          }
        }
      })
    );
  };

  checkScheduleAndSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    let mailStatus: string = e.currentTarget.dataset.mail || '';
    let addTypex: string = e.currentTarget.dataset.addtype || '';
    let dayHasAnyTrueValues: any = [];
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      dayHasAnyTrueValues = daysClone.map((day: string) => {
        return scheduleClone[day]
          .map((item: any) =>
            item.status == 'own_exam' || item.status == 'invigilator' || item.status == 'both' ? 1 : 0
          )
          .includes(1);
      });
      if (!dayHasAnyTrueValues.includes(true)) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Saat seçmediniz!',
            body: T.t('gen_select_hours_before_saving'),
            name: 'examPeriod_delete',
            icon: 'error_outline',
            iconColor: 'red',
            cancel: T.t('gen_close')
          })
        );
      } else if (dayHasAnyTrueValues.includes(true)) {
        this.saveInstructorHours(mailStatus == 'true', addTypex);
      }
    }
    if (!this.state.model.scheduleHasTrueValues) {
    } else if (this.state.model.scheduleHasTrueValues) {
    }
  };

  onCheckInstructor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList: any = this.state.model.checked_list;
      let newList = Object.assign([], checkedList);
      const stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);
      if (e.currentTarget.checked) {
        newList.push(id);
      } else if (!e.currentTarget.checked) {
        newList = checkedList.filter((item: number) => item != id);
      }
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          checked_list: newList
        }
      });
    }
  };

  onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_INSTRUCTOR_HOURS,
            this.state.filters,
            'ep-course-list',
            (response: any) =>
              this.setState({
                ...this.state,
                model: {
                  ...this.state.model,
                  checked_list: response.all_ids
                }
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          model: {
            ...this.state.model,
            checked_list: []
          },
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  adminSelectedHour = (day: string, hour: string) => {
    if (
      this.props.user &&
      this.props.user.applyCalendarRestrictions &&
      this.props.settings &&
      this.props.settings.calendar &&
      this.props.settings.calendar.time_slots &&
      Object.keys(this.props.settings.calendar.time_slots)
    ) {
      const time_slots = this.props.settings.calendar.time_slots;
      return time_slots[day] && time_slots[day].includes(hour) ? false : true;
    } else return true;
  };

  handleAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && this.props.instructorList) {
      this.state.model.instructor_list.map((instructor) => {
        if (instructor.instructor_id && !this.state.model.checked_list.find((i) => i === instructor.instructor_id)) {
          this.state.model.checked_list.push(instructor.instructor_id);
        }
      });
      this.state.model.all_checked = true;
    } else if (!e.target.checked && this.state.model.instructor_list) {
      this.state.model.instructor_list.map((instructor) => {
        var index = this.state.model.checked_list.indexOf(instructor.instructor_id);
        if (index !== -1) {
          this.state.model.checked_list.splice(index, 1);
        }
      });
      this.state.model.all_checked = false;
    }
    this.setState(this.state);
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CalendarModalSearchInitialValues);
    this.setState(this.state);
    this.getInstructorHours();
  };

  onFilterInstructorHours(model: Types.IFilterInstructor, FormActions: FormikActions<Types.IFilterInstructor>) {
    this.state.filters.query = model.name;
    this.state.filters.page = 1;
    this.state.filters.faculty_ids = model.faculty_ids;
    this.state.filters.program_ids = model.program_ids;
    this.state.filters.title_ids = model.title_ids;
    this.state.filters.staff_type_ids = model.staff_type_ids;
    this.setState(this.state);
    this.getInstructorHours();
    FormActions.setSubmitting(false);
  }

  onSelectInstructor = (e?: React.MouseEvent<HTMLDivElement>, role?: string) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      let numID = parseInt(id, 10);
      const selectedInstructor = this.state.model.instructor_list.find((person) => person.instructor_id === numID);

      if (this.state.model.selected_Instructor && this.state.model.selected_Instructor.instructor_id != numID) {
        this.state.model.selected_Instructor = selectedInstructor;

        this.state.model.invigilations.invigilation_campuses =
          selectedInstructor && selectedInstructor.instructorInfo
            ? selectedInstructor.instructorInfo.invigilation_campuses
            : [];

        this.state.model.invigilations.invigilation_faculties =
          selectedInstructor && selectedInstructor.instructorInfo
            ? selectedInstructor.instructorInfo.invigilation_faculties
            : [];

        this.state.model.invigilations.invigilation_programs =
          selectedInstructor && selectedInstructor.instructorInfo
            ? selectedInstructor.instructorInfo.invigilation_programs
            : [];

        this.state.model.invigilations.invigilator_level =
          selectedInstructor &&
            selectedInstructor.instructorInfo &&
            selectedInstructor.instructorInfo.invigilator_level !== undefined
            ? selectedInstructor.instructorInfo.invigilator_level
            : -1;

        this.state.model.calendar.schedule = this.state.model.initialSchedule
          ? JSON.parse(JSON.stringify(this.state.model.initialSchedule))
          : {};
      }
      this.convertDataToSchedule();
      this.selectRightSideActiveTab('hours');
    }
    if (role && role == 'instructorOnly') {
      this.state.model.selected_Instructor = this.state.model.instructor_list[0];
      this.convertDataToSchedule();
      this.selectRightSideActiveTab('hours');
      this.setState(this.state);
    }
  };

  selectActiveTab = (tab_number: number) => {
    let tabs =
      this.state.model.tabManager &&
      this.state.model.tabManager.map((tab) => {
        return tab.key == tab_number ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item) => item.value == true);
    let tabKey = activeTab && activeTab.key;

    if (this.state.model.active_tab !== tabKey) {
      this.state.model.tabManager = tabs;
      this.state.filters = Object.assign({}, CalendarModalSearchInitialValues);
      this.state.filters.active_tab = tabKey;
      this.state.model.active_tab = tabKey ? tabKey : 0;
      this.state.model.checked_list = [];
      this.state.model.selected_Instructor = SelectedInstructorEmptyValues;
      this.setState(this.state);
      this.getInstructorHours();
    }

    this.state.model.filterIsOpen = false;
  };

  selectRightSideActiveTab = (tab_name: string) => {
    let tabs =
      this.state.model.rightSideTabs &&
      this.state.model.rightSideTabs.map((tab) => {
        return tab.key == tab_name ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });
    this.state.model.rightSideTabs = tabs;
    this.setState(this.state);
  };

  isRightSideActiveTab = (tab_name: string) => {
    let activeTab =
      this.state.model.rightSideTabs && this.state.model.rightSideTabs.find((item) => item.key == tab_name);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  isActiveTab = (tab_name: number) => {
    let activeTab = this.state.model.tabManager && this.state.model.tabManager.find((item) => item.key == tab_name);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  static getDerivedStateFromProps(
    props: Types.IExamPeriod_InstructorProps,
    state: Types.IExamPeriod_Instructor_TabState
  ) {
    let hasNewState: boolean = false;

    if (props.instructorList) {
      hasNewState = true;
      state.model.instructor_list = props.instructorList;
      if (
        props.storedCalendar &&
        props.storedCalendar.times &&
        props.storedCalendar.days &&
        Object.keys(props.storedCalendar.schedule).length &&
        !state.model.is_calendar_created
      ) {
        state.model.calendar = props.storedCalendar;
      }
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.model.all_ids = props.all_ids;
    }

    if (props.filters && props.filters.active_tab != undefined) {
      hasNewState = true;
      state.model.active_tab = props.filters.active_tab;
      let tabs =
        state.model.tabManager &&
        state.model.tabManager.map((tab) => {
          return tab.key == (props.filters && props.filters.active_tab)
            ? { key: tab.key, value: true }
            : { key: tab.key, value: false };
        });
      state.model.tabManager = tabs;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let { instructor_list, checked_list, selected_Instructor } = this.state.model;
    let { times, days } = this.state.model.calendar;

    let instructor_list_ids = instructor_list.map((person) => person.instructor_id);
    let tableColumnHeads = days.map((day) => (
      <th key={day}>
        <div data-day={day} onClick={this.chooseAllHoursOfDay}>
          {Days.map(item => item.value === day ? T.t(item.label) : '')}
        </div>
      </th>
    ));
    let timeTableRows = null;
    timeTableRows = times.map((time: string) => {
      let boxes = Array(days.length)
        .fill(undefined)
        .map((val, index) => {
          let day: string = days[index];
          let scheduleClone: any = this.state && this.state.model.calendar && this.state.model.calendar.schedule;
          return (
            <td key={index}>
              <div
                id='div_choose_hour'
                data-day={day}
                data-hour={time}
                onClick={this.chooseHour}
                className={
                  scheduleClone[day].find((item: any) => item.hour == time).status == 'empty'
                    ? ''
                    : scheduleClone[day].find((item: any) => item.hour == time).status == 'own_exam'
                      ? 'own_exam blue'
                      : scheduleClone[day].find((item: any) => item.hour == time).status == 'invigilator'
                        ? 'invigilator red'
                        : scheduleClone[day].find((item: any) => item.hour == time).status == 'both'
                          ? 'both green'
                          : 'unavailable'
                }
              ></div>
            </td>
          );
        });
      let keyId = Math.random() * 1000
      return (
        <tr key={keyId}>
          <td className="aplan-tm-sticky-col">
            <div data-time={time} onClick={this.chooseAllDaysofHour}>
              {time}
            </div>
          </td>
          {boxes}
        </tr>
      );
    });
    let InstructorPanel =
      <React.Fragment>
        {this.state.model.checked_list && this.state.model.checked_list.length ? (
          <div className="align-items-center d-inline-flex w-100 mb-4">
            <label className="tick-radio d-inline-block">
              <input
                type="checkbox"
                name="check-all"
                checked={this.checkAllSelected()}
                onChange={this.onCheckAll}
                id="radio-three"
                className="form-radio"
              />
              <span className="ml-2">{T.t('gen_select_all')}</span>
            </label>
            <div
              className="d-inline-flex align-self-center align-items-center sidebar-controls"
              style={{ right: '5px' }}
            >
              <span
                id='add_list'
                className="category-tag-square tag-glass mr-4"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    model: {
                      ...this.state.model,
                      checked_list: []
                    }
                  });
                }}
              >
                <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                  close
                </i>
                {T.t('gen_cancel')}
              </span>
              <span id='delete_list' className="category-tag-square tag-red mr-4" onClick={this.deleteInstructors}>
                <i data-toggle="tooltip" className="material-icons" data-original-title="Listeden Çıkar">
                  delete
                </i>
                {T.t('gen_delete')} ({this.state.model.checked_list.length})
              </span>
            </div>
          </div>
        ) : this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e') ? (
          <React.Fragment>
            <div className="align-items-center d-inline-flex w-100 mb-4">
              <label className="tick-radio d-inline-block">
                <input
                  type="checkbox"
                  name="demo"
                  checked={this.checkAllSelected()}
                  onChange={this.onCheckAll}
                  id="radio-three"
                  className="form-radio"
                />
                <span className="ml-2">{T.t('gen_select_all')}</span>
              </label>
              <div
                className="d-inline-flex align-self-center align-items-center sidebar-controls"
                style={{ right: '5px' }}
              >
                <span
                  id='playlist_add'
                  className="category-tag-square tag-green mr-4"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      model: {
                        ...this.state.model,
                        addModalIsOpen: !this.state.model.addModalIsOpen
                      }
                    });
                  }}
                  style={this.state.model.active_tab == 0 ? {} : { display: 'none' }}
                >
                  <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                    playlist_add
                  </i>
                  {T.t("gen_add")}
                </span>
                <span
                  id='filter_list'
                  className="category-tag-square tag-glass float-right"
                  onClick={() => {
                    this.state.model.filterIsOpen = !this.state.model.filterIsOpen;
                    this.setState(this.state);
                  }}
                >
                  <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                    filter_list
                  </i>
                  {T.t('gen_filter')}
                </span>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div
          id="advance-search"
          className={`white-container collapse mb-2 ` + (this.state.model.filterIsOpen ? `show` : ``)}
        >
          <div className="advance-search d-block">
            <Formik
              initialValues={this.state.filters}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                this.onFilterInstructorHours(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterInstructor>) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-input form-group date-picker">
                        <input
                          id="name"
                          name="name"
                          value={props.values.name}
                          onChange={props.handleChange}
                          type="text"
                          required
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              props.handleSubmit();
                            }
                          }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="name">{T.t("gen_name")}</label>
                        <i className="material-icons">name</i>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="react-select-container">
                        <label>{T.t('gen_title')}</label>
                        <Select
                          id='select_title'
                          className="react-select"
                          isMulti={true}
                          filterOption={(option: any, query: any) =>
                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                          }
                          closeMenuOnSelect={false}
                          options={Titles(T)}
                          placeholder={T.t('gen_select_title')}
                          value={props.values.titles ? props.values.titles : null}
                          onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                            const list: Array<Types.ISelectOption> = options
                              ? (options as Array<Types.ISelectOption>)
                              : [];
                            props.setFieldValue('titles', list);
                            props.setFieldValue(
                              'title_ids',
                              list.map((item) => item.value)
                            );
                          }}
                          noOptionsMessage={(): string => T.t('gen_select_no_title')}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="react-select-container">
                        <label>{T.t('gen_staff')}</label>
                        <Select
                          id='select_staff_type'
                          className="react-select"
                          filterOption={(option: any, query: any) =>
                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                          }
                          options={StaffTypes(T)}
                          placeholder={T.t('gen_select_staff_type')}
                          value={props.values.staff_types ? props.values.staff_types : null}
                          onChange={(option: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                            let item = option as Types.ISelectOption;
                            props.setFieldValue('staff_types', item);
                            props.setFieldValue('staff_type_ids', [item.value]);
                          }}
                          noOptionsMessage={(): string => T.t('gen_select_no_staff_type')}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="add-custom-tag mb-3">
                        <div className="react-select-container">
                          <label>{T.t('gen_faculty_of_duty')}</label>
                          <Select
                            id='select_location'
                            className="react-select"
                            isMulti={true}
                            filterOption={(option: any, query: any) =>
                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                            }
                            closeMenuOnSelect={false}
                            options={
                              this.props.selectOptions && this.props.selectOptions.faculties
                                ? this.props.selectOptions.faculties
                                : []
                            }
                            placeholder={T.t('gen_select_location')}
                            value={props.values.faculties}
                            onChange={(
                              options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                            ) => {
                              const list: Array<Types.ISelectOption> = options
                                ? (options as Array<Types.ISelectOption>)
                                : [];
                              props.setFieldValue('faculties', list);
                              props.setFieldValue(
                                'faculty_ids',
                                list.map((item) => item.value)
                              );
                              this.getProgramsByFacultiesAtInstructors(list.map(item => item.value))
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="add-custom-tag mb-3">
                        <div className="react-select-container">
                          <label>{T.t('gen_program_of_duty')}</label>
                          <Select
                            id='select_program'
                            className="react-select"
                            isMulti={true}
                            filterOption={(option: any, query: any) =>
                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                            }
                            closeMenuOnSelect={false}
                            options={
                              this.props.programs_related_faculty && this.props.programs_related_faculty !== undefined ?
                                (this.props.programs_related_faculty && this.props.programs_related_faculty
                                  ? this.props.programs_related_faculty
                                  : []
                                ) : undefined
                            }
                            placeholder={T.t('gen_select_program')}
                            value={props.values.programs}
                            onChange={(
                              options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                            ) => {
                              const list: Array<Types.ISelectOption> = options
                                ? (options as Array<Types.ISelectOption>)
                                : [];
                              props.setFieldValue('programs', list);
                              props.setFieldValue(
                                'program_ids',
                                list.map((item) => item.value)
                              );
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_program')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="add-custom-tag mb-3">
                        <div className="react-select-container">
                          <label>{T.t('gen_daily_max_course')}</label>
                          <Select
                            id='select_daily_max_classes'
                            className="react-select"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            options={DailyMaxClasses}
                            placeholder={T.t('gen_select_daily_max_classes')}
                            value={props.values.daily_max_classes}
                            onChange={(
                              options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                            ) => {
                              const list: Array<Types.ISelectOption> = options
                                ? (options as Array<Types.ISelectOption>)
                                : [];
                              props.setFieldValue('daily_max_classes', list);
                              props.setFieldValue(
                                'daily_max_classes_ids',
                                list.map((item) => item.value)
                              );
                              this.state.filters.daily_max_classes_ids = list.map((item) => item.value);
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_daily_max_classes')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <button
                        id='button_arrow_upward'
                        type="button"
                        onClick={() => {
                          this.state.model.filterIsOpen = false;
                          this.setState(this.state);
                        }}
                        className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                      >
                        <i className="material-icons">arrow_upward</i>
                      </button>
                      <button
                        id='button_delete_sweep'
                        type="reset"
                        onClick={props.handleReset}
                        className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                      >
                        <i className="material-icons">delete_sweep</i>
                      </button>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        id='button_search'
                        type="button"
                        className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                        onClick={() => props.handleSubmit()}
                        disabled={props.isSubmitting}
                      >
                        <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="small-wrapper" style={this.state.model.filterIsOpen ? { marginTop: '32px' } : {}}>
          <div className="shortened-list">
            {instructor_list.map((instructor) => {
              let keyId = instructor.instructor_id + (Math.random() * 1000)
              return (
                <div className="d-inline-flex w-100" key={keyId}>
                  {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e') ? (
                    <span className="tick-radio position-relative mr-3">
                      <input
                        type="checkbox"
                        name={instructor.instructorInfo && instructor.instructorInfo.name}
                        data-id={instructor.instructor_hour_id}
                        onChange={this.onCheckInstructor}
                        checked={
                          (this.state.model.checked_list &&
                            this.state.model.checked_list.includes(
                              instructor.instructor_hour_id ? instructor.instructor_hour_id : 0
                            )) || this.checkAllSelected()
                        }
                        id="radio-1"
                        className="form-radio"
                      />
                    </span>
                  ) : null}
                  <div
                    id='div_select_instructor'
                    className="sl-item d-inline-flex align-items-center align-self-center w-100 pt-2 pb-2 pl-2 pr-2"
                    data-id={instructor.instructor_id}
                    onClick={this.onSelectInstructor}
                    key={instructor.instructor_id}
                    style={
                      selected_Instructor && selected_Instructor.instructor_id == instructor.instructor_id
                        ? {
                          backgroundColor: 'rgba(0, 0, 0, 0.075)',
                          border: '1px solid darkgrey'
                        }
                        : {}
                    }
                  >
                    <span id={"description-popover-" + instructor.instructor_id} className="text-truncate">
                      {instructor.instructorInfo && instructor.instructorInfo.title}
                      &nbsp;
                      {instructor.instructorInfo && instructor.instructorInfo.name}
                      &nbsp;
                      {instructor.instructorInfo && instructor.instructorInfo.description != undefined && instructor.instructorInfo && instructor.instructorInfo.description != "" ? (
                        <><label><i className="material-icons">info_outlined</i></label>
                          <UncontrolledTooltip key={instructor.instructor_id} placement="bottom" target={"description-popover-" + instructor.instructor_id}>
                            {instructor.instructorInfo && instructor.instructorInfo.description}
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                    </span>
                    <div className="align-items-center align-self-center ml-auto sidebar-controls"></div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="row-options justify-content-end">
            <div
              className="page-sorting d-flex align-items-center justify-content-center"
              style={{ marginTop: '5px' }}
            >
              <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
            </div>
          </div>
        </div>
      </React.Fragment>

    //TODO: BOTTOM BUTTONS
    let BottomButtons =
      (this.state.model.active_tab == 0 && (
        <React.Fragment>
          {this.state.model.checked_list && this.state.model.checked_list.length > 1 ? (
            this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e') ? (
              <button
                id='button_save_all'
                type="button"
                className="btn btn-blue mt-2 mb-2 float-right"
                data-mail="false"
                data-addtype="multiple"
                onClick={this.checkScheduleAndSave}
              >
                <i className="material-icons mr-2 text-left">done_all</i>
                <span>{T.t('gen_save_multi')}</span>
              </button>
            ) : null
          ) : (
            this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e') ? (
              <button
                id='button_save'
                type="button"
                className="btn btn-green mt-2 mb-2 mr-2 float-right"
                data-mail="false"
                data-addtype="single"
                onClick={this.checkScheduleAndSave}
              >
                <i className="material-icons mr-2 text-left">done</i>
                <span>{T.t('gen_save')}</span>
              </button>
            ) : null
          )}
        </React.Fragment>
      )) ||
      (this.state.model.active_tab == 3 && (
        <React.Fragment>
          {this.state.model.checked_list && this.state.model.checked_list.length > 1 ? (
            this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e') ? (
              <button
                id='button_save_update'
                type="button"
                className="btn btn-blue mt-2 mb-2 float-right"
                data-mail="false"
                data-addtype="multiple"
                onClick={this.checkScheduleAndSave}
              >
                <i className="material-icons mr-2 text-left">done_all</i>
                <span>{T.t('gen_save_update_multi')}</span>
              </button>
            ) : null
          ) : (
            this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e') ? (
              <button
                id='button_update_save'
                type="button"
                className="btn btn-green mt-2 mb-2 mr-2 float-right"
                data-mail="false"
                data-addtype="single"
                onClick={this.checkScheduleAndSave}
              >
                <i className="material-icons mr-2 text-left">done</i>
                <span>{T.t('gen_save_update')}</span>
              </button>
            ) : null
          )}
        </React.Fragment>
      ));

    let onInstructorSelectOwn = (
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="generic-wrapper">
          <div
            className={this.isRightSideActiveTab('hours') ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="self-exams"
            role="tabpanel"
            aria-labelledby="self-exams-tab"
          >
            <div className="row">
              <div className="col-12">
                <React.Fragment>
                  <div className="mr-auto">
                    <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
                      <input
                        type="checkbox"
                        name="invigilator"
                        id="invigilator"
                        checked={this.state.model.addType == 'both' ? true : false}
                        onChange={() => {
                          this.state.model.addType = 'both';
                          this.setState(this.state);
                        }}
                        className="form-radio green"
                        defaultChecked
                      />
                      <span className="ml-2">{T.t('gen_avaliable_for_both')}</span>
                    </label>
                    <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
                      <input
                        type="checkbox"
                        name="own_exam"
                        id="own_exam"
                        checked={this.state.model.addType == 'own_exam' ? true : false}
                        onChange={() => {
                          this.state.model.addType = 'own_exam';
                          this.setState(this.state);
                        }}
                        className="form-radio blue"
                        defaultChecked
                      />
                      <span className="ml-2">{T.t('gen_own_exams')}m</span>
                    </label>
                    <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
                      <input
                        type="checkbox"
                        name="invigilator"
                        id="invigilator"
                        checked={this.state.model.addType == 'invigilator' ? true : false}
                        onChange={() => {
                          this.state.model.addType = 'invigilator';
                          this.setState(this.state);
                        }}
                        className="form-radio red"
                        defaultChecked
                      />
                      <span className="ml-2">Gözetmenliklerim</span>
                    </label>
                  </div>
                  <div className="aplan-table-matrix-wrapper">
                    <div
                      className="aplan-table-matrix-scroller"
                      style={this.state.model.isExpanded ? { height: '100%' } : { height: '434px' }}
                    >
                      <table className="table table-borderless table-striped table-hover aplan-table-matrix course-table mb-0">
                        <thead>
                          <tr>
                            <th className="aplan-tm-sticky-col">
                              <div>#</div>
                            </th>
                            {tableColumnHeads}
                          </tr>
                        </thead>
                        <tbody>{timeTableRows}</tbody>
                      </table>
                    </div>
                  </div>
                  <button
                    id='button_check_shedule_save'
                    type="button"
                    // data-mail="true"
                    data-addtype="single"
                    onClick={this.checkScheduleAndSave}
                    className="btn btn-blue mt-2 mb-2 float-right"
                  >
                    <i className="material-icons mr-2">save</i>
                    <span className="text-left">{T.t('gen_save')}</span>
                  </button>
                  <button
                    id='button_expand'
                    type="button"
                    className="btn btn-gray mt-2 mb-2 float-none"
                    onClick={() => {
                      this.state.model.isExpanded = !this.state.model.isExpanded;
                      this.setState(this.state);
                    }}
                  >
                    <i className="material-icons mr-2 text-left">{this.state.model.isExpanded ? 'remove' : 'add'}</i>
                    <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                  </button>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="row">
        <React.Fragment>
          <div className="col-xl-3 col-lg-3 col-md-5">
            <div className="generic-wrapper">
              <div className="row">
                <div className="col-12">
                  <nav className="inner-page-nav left-bar">
                    <div
                      className="nav nav-tabs nav-fill mb-4 d-inline-flex align-items-end w-100"
                      id="nav-tab4"
                      role="tablist"
                    >
                      <a
                        className={this.isActiveTab(0) ? 'nav-item nav-link active' : 'nav-item nav-link'}
                        onClick={() => this.selectActiveTab(0)}
                        id="action-tab"
                        data-toggle="tab"
                        href="#action"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_action')}
                      </a>
                      <React.Fragment>
                        <a
                          className={this.isActiveTab(3) ? 'nav-item nav-link active' : 'nav-item nav-link'}
                          onClick={() => this.selectActiveTab(3)}
                          id="confirmed-tab"
                          data-toggle="tab"
                          href="#confirmed"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="false"
                        >
                          {T.t('gen_results')}
                        </a>
                      </React.Fragment>
                    </div>
                  </nav>
                  <Spinner name="ep-instructor-list" />
                  <div className="tab-content left-bar-content" id="nav-tabContent4">
                    <div
                      className={this.isActiveTab(0) ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="action"
                      role="tabpanel"
                      aria-labelledby="action-tab"
                    >
                      {InstructorPanel}
                    </div>
                    <div
                      className={this.isActiveTab(3) ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="confirmed"
                      role="tabpanel"
                      aria-labelledby="confirmed-tab"
                    >
                      {InstructorPanel}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-7">
            <div className="generic-wrapper">
              {Object.keys(this.state.model.calendar.schedule).length !== 0 &&
                this.state.model.selected_Instructor &&
                this.state.model.selected_Instructor.instructor_id ? (
                <React.Fragment>
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-4" id="nav-tab2" role="tablist">
                      <a
                        className={
                          this.isRightSideActiveTab('hours') ? 'nav-item nav-link active show' : 'nav-item nav-link'
                        }
                        id="self-exams-tab"
                        data-toggle="tab"
                        href="#self-exams"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="false"
                      >
                        {T.t('gen_hours')}
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent2">
                    <div
                      className={this.isRightSideActiveTab('hours') ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="self-exams"
                      role="tabpanel"
                      aria-labelledby="self-exams-tab"
                    >
                      <div className="row">
                        <div className="col-12">
                          <React.Fragment>
                            <div className="aplan-table-matrix-wrapper">
                              <div
                                className="aplan-table-matrix-scroller"
                                style={this.state.model.isExpanded ? { height: '100%' } : { height: '434px' }}
                              >
                                <table className="table table-borderless table-striped table-hover aplan-table-matrix course-table mb-0">
                                  <thead>
                                    <tr>
                                      <th className="aplan-tm-sticky-col">
                                        <div>#</div>
                                      </th>
                                      {tableColumnHeads}
                                    </tr>
                                  </thead>
                                  <tbody>{timeTableRows}</tbody>
                                </table>
                              </div>
                            </div>
                            {BottomButtons}
                            <button
                              id='button_is_expand'
                              type="button"
                              className="btn btn-gray mt-2 mb-2 float-none"
                              onClick={() => {
                                this.state.model.isExpanded = !this.state.model.isExpanded;
                                this.setState(this.state);
                              }}
                            >
                              <i className="material-icons mr-2 text-left">
                                {this.state.model.isExpanded ? 'remove' : 'add'}
                              </i>
                              <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                            </button>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <img
                    id='instructor_hours_area_info'
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '40%'
                    }}
                    src={Announcement}
                  />
                  <br />
                  <p style={{ textAlign: 'center' }}>
                    {T.t('gen_instructor_hours_area_info')} <br /> {T.t('gen_remember_to_save_hours')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <InstructorAddModal
            modalIsOpen={this.state.model.addModalIsOpen}
            onClose={this.closeAddModal}
            onUpdateList={() => this.getInstructorHours()}
          />
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,

  ownProps: Types.IExamPeriod_InstructorProps
): Types.IExamPeriod_InstructorProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamPeriod_InstructorProps = Object.assign({}, ownProps, {
    instructorList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.instructor_hours &&
      store.state.examPeriodModal.instructor_hours.instructor_list,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.instructor_hours &&
      store.state.examPeriodModal.instructor_hours.all_ids,
    storedCalendar: store.state.examPeriodModal && store.state.examPeriodModal.storedCalendar,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    selectOptions: store.state.select_options && store.state.select_options.instructorPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    user: store.state.user
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  /*if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal,
        next.state.examPeriodModal && next.state.examPeriodModal
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.instructor_hours,
        next.state.examPeriodModal && next.state.examPeriodModal.instructor_hours
      )
    );
  } else {
    return true;
  }*/
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(Schedule);

export default container;
