import { Log } from 'ng2-logger';
import { put, takeEvery } from 'redux-saga/effects';
import MockApiClient from '../../services/mock-api-client';
import * as Actions from '../actions/general';
import * as actions from '../constants/all';
import * as Types from '../types';

const L = Log.create('user-saga');

function* getUsers(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  const response: Response = yield papi.GetResponse(papi.UserSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield papi.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getUserSelectOptions(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  const response: Response = yield papi.GetResponse(papi.UserSelectOptions());
  let content: any = null;
  if (response && response.status === 200) {
    content = yield papi.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getUserData(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield papi.GetResponse(papi.UserGetById(action.body, action.qs));
    if (response && response.status === 200) {
      content = yield papi.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createUser(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  const response: Response = yield papi.GetResponse(papi.UserCreate(action.body));
  let content: any = yield papi.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_user_created', 'gen_success'));
  } else if (response && response.status === 409 || response.status === 400) {
    yield put(Actions.Notification('notification_user_already_added_information', 'gen_warning', 'danger'))
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateUsersRole(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  const response: Response = yield papi.GetResponse(papi.UserUpdateRole(action.body));
  let content: any = yield papi.GetContent(response);

  if (!(response && response.status === 200)) {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }

  yield put(Actions.ApiResponse(action, response, content));
}

function* createUserBulk(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield papi.GetResponse(papi.UserCreateBulk(action.body));
    if (response && (response.status === 200 || response.status === 400)) {
      content = yield papi.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateUser(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  const response: Response = yield papi.GetResponse(papi.UserUpdate(action.body));
  let content: any = yield papi.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_user_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteUsers(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  const response: Response = yield papi.GetResponse(papi.UserDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    if (action.callback) {
      action.callback(response.status);
    }
    content = yield papi.GetContent(response);
    yield put(Actions.Notification('gen_user_deleted', 'gen_success'));
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('notification_admin_delete', 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* browseUsers(action: Types.IApiRequestAction) {
  const papi: MockApiClient = new MockApiClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield papi.GetResponse(papi.UserBrowse(action.body));
    if (response && response.status === 200) {
      content = yield papi.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getUserGetProgramsByFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockApiClient = new MockApiClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.UserGetProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getUserGetFilterProgramsByFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockApiClient = new MockApiClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.UserGetFilterProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchUserSearch() {
  yield takeEvery((action: any) => action.target === actions.user.USER_LIST_SEARCH, getUsers);
}

export function* watchUserSelectOptions() {
  yield takeEvery((action: any) => action.target === actions.user.USER_SELECT_OPTIONS, getUserSelectOptions);
}

export function* watchUserCreate() {
  yield takeEvery((action: any) => action.target === actions.user.USER_CREATE, createUser);
}

export function* watchUserUpdate() {
  yield takeEvery((action: any) => action.target === actions.user.USER_UPDATE, updateUser);
}

export function* watchUserGetById() {
  yield takeEvery((action: any) => action.target === actions.user.USER_GET_BY_ID, getUserData);
}

export function* watchDeleteUsers() {
  yield takeEvery((action: any) => action.target === actions.user.USER_DELETE, deleteUsers);
}

export function* watchUserBrowse() {
  yield takeEvery(
    (action: any) =>
      action.target === actions.user.USER_BROWSE ||
      action.target === actions.user.USER_BROWSE_ALL ||
      action.target === actions.user.USER_BROWSE_GET_BY_IDS,
    browseUsers
  );
}

export function* watchCreateUserBulk() {
  yield takeEvery((action: any) => action.target === actions.user.USER_CREATE_BULK, createUserBulk);
}

export function* watchUpdateUserRole() {
  yield takeEvery((action: any) => action.target === actions.user.USER_UPDATE_ROLE, updateUsersRole);
}

export function* watchUserGetProgramsByFaculties() {
  yield takeEvery((action: any) => action.target === actions.user.USER_GET_PROGRAMS_BY_FACULTIES, getUserGetProgramsByFaculties);
}

export function* watchUserGetFilterProgramsByFaculties() {
  yield takeEvery((action: any) => action.target === actions.user.USER_GET_FILTER_PROGRAMS_BY_FACULTIES, getUserGetFilterProgramsByFaculties);
}