import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
import PerculusApiClient from '../../services/perculus-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';
const L = Log.create('dashboard-saga');

function* getSessions(action: Types.IApiRequestAction) {
  const papi: PerculusApiClient = new PerculusApiClient();
  const response: Response = yield papi.GetResponse(papi.DashboardGetSessions());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield papi.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchDashboardGetSessions() {
  yield takeEvery((action: any) => action.target === actions.dasboard.DASHBOARD_GET_SESSIONS, getSessions);
}
